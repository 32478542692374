<template>
    <div>
        <div class="Btn-TimeSelect" v-click-outside="handleClickOutsideChooseTime">
            <span
                class="ValueOption"
                v-on:click="showTimeOptionBindingStyle"
                >{{ dateRangeString.dateStart }} -
                {{ dateRangeString.dateEnd }}</span
            >
            <span class="material-icons ml-8" v-on:click="showTimeOptionBindingStyle"> calendar_month </span>
            <div ref="ChooseTime">
                <div class="WrapChooseTime">
                    <div class="TimePicker" >
                        <span @mouseleave="mouseLeave('Now')" @mouseover="getTimeRangeHover('Now')"  class="optionTime">
                            {{ $t("Report.ReportTimeToNow")}}
                        </span>
                        <span 
                            @mouseout="
                                handleClickOutsideSpanTimeOption(
                                    'YesterdaySpanTimeOption'
                                )"
                            @click="getTimeRange('yesterday')"
                            @mouseover="getTimeRangeHover('yesterday')" 
                            class="optionTime"
                        >
                            {{ $t("Report.Yesterday") }}
                        </span>
                        <span 
                            @mouseout="
                                handleClickOutsideSpanTimeOption(
                                    'SevenDaysAgoSpanTimeOption'
                                )"
                            @click="getTimeRange('sevenDaysAgo')"
                            @mouseover="getTimeRangeHover('sevenDaysAgo')" 
                            class="optionTime"
                        >
                            {{ $t("Report.TheLastSevenDays") }}
                        </span>
                        <span 
                            @click="getTimeRange('thirtyDaysAgo')"
                            @mouseover="
                                getTimeRangeHover('thirtyDaysAgo')"
                            @mouseout="
                                handleClickOutsideSpanTimeOption(
                                    'ThirtyDaysAgoSpanTimeOption'
                                )"
                            class="optionTime bbt"
                        >
                            {{ $t("Report.TheLastThirtyDays") }}
                        </span>
                        <span
                            @mouseover="showDatePicker('1')"
                            @mouseout="cancelTimeout('1')" 
                            class="optionTime day line"
                        >
                            {{ $t("Report.ByDay") }}
                        </span>
                        <span 
                            @mouseover="showDatePicker('2')"
                            @mouseout="cancelTimeout('2')" 
                            class="optionTime week"
                        >
                            {{ $t("Report.ByWeek") }}
                        </span>
                        <span
                            @mouseover="showDatePicker('3')"
                            @mouseout="cancelTimeout('3')" 
                            class="optionTime month"
                        >
                            {{ $t("Report.ByMonth") }}
                        </span>
                        <span 
                            class="optionTime year"
                            @mouseover="showDatePicker('4')"
                            @mouseout="cancelTimeout('4')"
                        >
                            {{ $t("Report.ByYear") }}
                        </span>
                        <span 
                            class="optionTime rangeTime"
                            @mouseover="showDatePicker('5')"
                            @mouseout="cancelTimeout('5')"
                        >            
                            {{ $t("Report.CustomizeDateSelection") }}
                        </span>
                    </div>
                    <div class="ShowValueTime" v-bind:class="{ ShowValueTimeWidth560: isCustomizeHover }">
                        <span class="ValueTime" ref="Now">
                            {{ $t("Report.Until") }} {{ currentTime }} {{ $t("Report.Today").toLowerCase() }}
                        </span>
                        <span
                            class="ValueTime ValueYesterday"
                            ref="YesterdaySpanTimeOption"
                        >
                            {{ dateRangeStringHover.dateStart }} -
                            {{ dateRangeStringHover.dateEnd }}
                        </span>
                        <span
                            class="ValueTime ValueYesterday"
                            ref="SevenDaysAgoSpanTimeOption"
                        >
                            {{ dateRangeStringHover.dateStart }} -
                            {{ dateRangeStringHover.dateEnd }}
                        </span>
                        <span
                            class="ValueTime ValueYesterday"
                            ref="ThirtyDaysAgoSpanTimeOption"
                        >
                            {{ dateRangeStringHover.dateStart }} -
                            {{ dateRangeStringHover.dateEnd }}
                        </span>
                        <span class="ValueTime position" ref="Day">
                            <date-picker
                                class="selecttime date"
                                v-model="dateValue"
                                format="YYYY-MM-DD"
                                type="date"
                                :open="showDayPicker"
                                :lang="lang"
                                v-click-outside="handleClickOutsideDayPicker"
                            ></date-picker>
                        </span>
                        <span class="ValueTime position" ref="Week">
                            <date-picker
                                :lang="lang"
                                class="selecttime weekpicker"
                                v-model="weekValue"
                                type="week"
                                :open="showWeekPicker"
                                v-click-outside="handleClickOutsideWeekPicker"
                            ></date-picker>
                        </span>
                        <span class="ValueTime position" ref="Month">
                            <date-picker
                                class="selecttime monthpicker"
                                v-model="monthValue"
                                type="month"
                                :open="showMonthPicker"
                                v-click-outside="handleClickOutsideMonthPicker"
                                :lang="lang"
                            ></date-picker>
                        </span>
                        <span class="ValueTime position" ref="Year">
                            <date-picker
                                class="selecttime yearpicker"
                                v-model="yearValue"
                                type="year"
                                :open="showYearPicker"
                                v-click-outside="handleClickOutsideYearPicker"
                                :lang="lang"
                            ></date-picker>
                        </span>
                        <span class="ValueTime position" ref="Custom">
                            <date-picker
                                class="selecttime rangepicker"
                                v-model="rangeTime"
                                type="date"
                                range
                                :open="showRangePicker"
                                v-click-outside="handleClickOutsideRangePicker"
                                :lang="lang"
                            >
                            </date-picker>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>
<script>
import * as moment from "moment-timezone";
import EventBus from "@/EventBus";

export default {
    mounted() {
        this.$refs["ChooseTime"].style.visibility = "hidden";
        this.$refs["SevenDaysAgoSpanTimeOption"].style.visibility = "hidden";
        this.$refs["ThirtyDaysAgoSpanTimeOption"].style.visibility = "hidden";
    },
    data() {
        return {
            active: false,
            currentTime: moment().format("HH:mm"),
            lang: localStorage.lang=="vn" ? "vi" : "en",
            dayTimeout: "",
            weekTimeout: "",
            monthTimeout: "",
            yearTimeout: "",
            rangeTimeout: "",
            timeOutYesterDay: "",

            showTimeOption: false,
            showDayPicker: false,
            showWeekPicker: false,
            showMonthPicker: false,
            showYearPicker: false,
            showRangePicker: false,
            isCustomizeHover: false,

            dateRange: {
                dateStart: moment()
                    .subtract(7, "day")
                    .startOf("day")
                    .format("X"),
                dateEnd: moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("X")
            },
            dateRangeString: {
                dateStart: moment()
                    .subtract(7, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                dateEnd: moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY")
            },
            dateRangeStringHover: {
                dateStart: "",
                dateEnd: ""
            },

            listDateRange: [],
            rangeTime: "",
            dateValue: "",
            weekValue: "",
            monthValue: "",
            yearValue: "",
        }
    },
    methods: {
        mouseOver(refName) {
            this.active = !this.active
            this.$refs[refName].style.visibility = "visible";
        },
        mouseLeave(refName) {
            this.active = this.active=false
            this.$refs[refName].style.visibility = "hidden";
        },
        showDatePicker(index) {
            let self = this;
            if (index == "1") {
                this.dayTimeout = setTimeout(function() {
                    self.showDayPicker = true;
                    self.showWeekPicker = false;
                    self.showMonthPicker = false;
                    self.showYearPicker = false;
                    self.showRangePicker = false;
                }, 350);
            } else if (index == "2") {
                this.weekTimeout = setTimeout(function() {
                    self.showDayPicker = false;
                    self.showWeekPicker = true;
                    self.showMonthPicker = false;
                    self.showYearPicker = false;
                    self.showRangePicker = false;
                }, 350);
            } else if (index == "3") {
                this.monthTimeout = setTimeout(function() {
                    self.showDayPicker = false;
                    self.showWeekPicker = false;
                    self.showMonthPicker = true;
                    self.showYearPicker = false;
                    self.showRangePicker = false;
                }, 350);
            } else if (index == "4") {
                this.yearTimeout = setTimeout(function() {
                    self.showDayPicker = false;
                    self.showWeekPicker = false;
                    self.showMonthPicker = false;
                    self.showYearPicker = true;
                    self.showRangePicker = false;
                }, 350);
            } else if (index == "5") {
                this.rangeTimeout = setTimeout(function() {
                    self.showDayPicker = false;
                    self.showWeekPicker = false;
                    self.showMonthPicker = false;
                    self.showYearPicker = false;
                    self.showRangePicker = true;
                }, 350);
            }
        },
        cancelTimeout(index) {
            var self = this;
            if (index == "1") {
                clearTimeout(this.dayTimeout);
            } else if (index == "2") {
                clearTimeout(this.weekTimeout);
            } else if (index == "3") {
                clearTimeout(this.monthTimeout);
            } else if (index == "4") {
                clearTimeout(this.yearTimeout);
            } else if (index == "5") {
                clearTimeout(this.rangeTimeout);
            }
        },
        handleClickOutsideChooseTime(index) {
            // this.cancelTimeout.log("alo")
            if (index == undefined) {
                this.$refs["ChooseTime"].style.display = "none";
                this.$refs["ChooseTime"].style.visibility = "hidden";
            } else if (
                index.srcElement._prevClass != "mx-icon-right" &&
                index.srcElement._prevClass != "mx-icon-left" &&
                index.srcElement._prevClass != "mx-icon-double-left" &&
                index.srcElement._prevClass != "mx-icon-double-right" &&
                index.srcElement._prevClass != "mx-calendar-header" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-right" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-right"  &&
                index.srcElement._prevClass !=
                    "mx-calendar mx-calendar-panel-date"
            ) {
                if (navigator.userAgent.indexOf("Firefox") == -1) {
                    if (index.path.length == 15) {
                        if (index.path[9]._prevClass != "mx-datepicker-content") {
                            this.$refs["ChooseTime"].style.display = "none";
                            this.$refs["ChooseTime"].style.visibility = "hidden";
                        }
                    } else {
                        this.$refs["ChooseTime"].style.display = "none";
                        this.$refs["ChooseTime"].style.visibility = "hidden";
                    }
                } else {
                    if (index.target.parentElement._prevClass != "cell" && index.target.parentElement._prevClass != "cell active" && index.target.parentElement._prevClass != "cell today active") {
                        this.$refs["ChooseTime"].style.display = "none";
                        this.$refs["ChooseTime"].style.visibility = "hidden";
                    }
                }
            }
        },
        handleClickOutsideSpanTimeOption(refInput) {
            this.$refs[refInput].style.visibility = "hidden";
        },
        showTimeOptionBindingStyle() {
            this.$refs["ChooseTime"].style.display = "block";
            this.$refs["ChooseTime"].style.visibility = "visible";
        },
        getTimeRange(timeOption) {
            this.compareType = "normal";
            if (timeOption === "yesterday") {
                let compareToText = "So với ngày ";
                if (localStorage.lang == "en") {
                    compareToText = "Compare to ";
                }
                this.compareType = "normal";
                this.compareText =
                    compareToText +
                    moment()
                        .subtract(2, "days")
                        .startOf("day")
                        .format("DD/MM/YYYY")
                this.dateRange.dateStart = moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("X");
                this.dateRange.dateEnd = moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("X");

                this.dateRangeString.dateStart = moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY");
                this.dateRangeString.dateEnd = moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY");

                this.handleClickOutsideChooseTime();
                
                this.getDateRange(
                    this.dateRange.dateStart,
                    this.dateRange.dateEnd
                );
            } else if (timeOption === "sevenDaysAgo") {
                this.compareText = this.$t("Report.CompareToThePriorSevenDays");
                this.dateRange.dateStart = moment()
                    .subtract(7, "day")
                    .startOf("day")
                    .format("X");
                this.dateRange.dateEnd = moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("X");

                this.dateRangeString.dateStart = moment()
                    .subtract(7, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY");
                this.dateRangeString.dateEnd = moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY");

                this.handleClickOutsideChooseTime();
            } else if (timeOption === "thirtyDaysAgo") {
                this.compareText = this.$t("Report.CompareToThePriorThirtyDays");
                this.dateRange.dateStart = moment()
                    .subtract(30, "day")
                    .startOf("day")
                    .format("X");
                this.dateRange.dateEnd = moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("X");

                this.dateRangeString.dateStart = moment()
                    .subtract(30, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY");
                this.dateRangeString.dateEnd = moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY");

                this.handleClickOutsideChooseTime();
            }
            this.getDateRange(this.dateRange.dateStart, this.dateRange.dateEnd);
            this.$emit("changeDateRange", this.dateRange)
            this.$emit("changeDateRangeString", this.dateRangeString)
            this.$emit("changeCompareText", this.compareText)
            this.$emit("changeCompareType", this.compareType)
            var dataDate = {
                compareType: this.compareType,
                dateRange: this.dateRange
            }
            EventBus.$emit("changeDateShopingMall", dataDate);
            EventBus.$emit("changeDateRetailStore", dataDate);
        },
        getTimeRangeHover(timeOption) {
            this.showRangePicker = false;
            this.showDayPicker = false;
            this.showWeekPicker = false;
            this.showMonthPicker = false;
            this.showYearPicker = false;
            if (timeOption === "yesterday") {
                this.$refs["YesterdaySpanTimeOption"].style.visibility =
                    "visible";
                this.dateRangeStringHover.dateStart = moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY");
                this.dateRangeStringHover.dateEnd = moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY");
            } else if (timeOption === "sevenDaysAgo") {
                this.$refs["SevenDaysAgoSpanTimeOption"].style.visibility =
                    "visible";
                this.dateRangeStringHover.dateStart = moment()
                    .subtract(7, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY");
                this.dateRangeStringHover.dateEnd = moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY");
            } else if (timeOption === "Now") {
                this.$refs["Now"].style.visibility =
                    "visible";
            }else if (timeOption === "thirtyDaysAgo") {
                this.$refs["ThirtyDaysAgoSpanTimeOption"].style.visibility =
                    "visible";
                this.dateRangeStringHover.dateStart = moment()
                    .subtract(30, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY");
                this.dateRangeStringHover.dateEnd = moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY");
            }
        },
        getDateRange(dateStart, dateEnd) {
            this.showDayPicker = false;
            this.showWeekPicker = false;
            this.showMonthPicker = false;
            this.showYearPicker = false;
            this.showRangePicker = false;
            dateStart = parseInt(dateStart);
            dateEnd = parseInt(dateEnd);
            this.listDateRange = [];
            while (dateStart <= dateEnd) {
                this.listDateRange.push(moment.unix(dateStart).format("DD/MM"));
                dateStart = dateStart + 86400;
            }
            this.$emit("changeListDateRange", this.listDateRange)
        },
        handleClickOutsideDayPicker(index) {
            
            if (
                this.showDayPicker == true &&
                index.srcElement._prevClass != "optionTime day line" &&
                index.srcElement._prevClass != "mx-icon-right" &&
                index.srcElement._prevClass != "mx-icon-left" &&
                index.srcElement._prevClass != "mx-icon-double-left" &&
                index.srcElement._prevClass != "mx-icon-double-right" &&
                index.srcElement._prevClass != "mx-calendar-header" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-right" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-right"
            ) {
                this.showDayPicker = false;
            }
        },
        handleClickOutsideWeekPicker(index) {
            if (
                this.showWeekPicker == true &&
                index.srcElement._prevClass != "optionTime week" &&
                index.srcElement._prevClass != "mx-icon-right" &&
                index.srcElement._prevClass != "mx-icon-left" &&
                index.srcElement._prevClass != "mx-icon-double-left" &&
                index.srcElement._prevClass != "mx-icon-double-right" &&
                index.srcElement._prevClass != "mx-calendar-header" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-right" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-right"
            ) {
                this.showWeekPicker = false;
            }
        },
        handleClickOutsideMonthPicker(index) {
            if (
                this.showMonthPicker == true &&
                index.srcElement._prevClass != "optionTime month" &&
                index.srcElement._prevClass != "mx-icon-right" &&
                index.srcElement._prevClass != "mx-icon-left" &&
                index.srcElement._prevClass != "mx-icon-double-left" &&
                index.srcElement._prevClass != "mx-icon-double-right" &&
                index.srcElement._prevClass != "mx-calendar-header" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-right" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-right"
            ) {
                this.showMonthPicker = false;
            }
        },
        handleClickOutsideYearPicker(index) {
            if (
                this.showYearPicker == true &&
                index.srcElement._prevClass != "optionTime year" &&
                index.srcElement._prevClass != "mx-icon-right" &&
                index.srcElement._prevClass != "mx-icon-left" &&
                index.srcElement._prevClass != "mx-icon-double-left" &&
                index.srcElement._prevClass != "mx-icon-double-right" &&
                index.srcElement._prevClass != "mx-calendar-header" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-right" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-right"
            ) {
                this.showYearPicker = false;
                if (navigator.userAgent.indexOf("Firefox") == -1) {
                    if (index.path.length == 15) {
                        if (index.path[9]._prevClass != "mx-datepicker-content") {
                            this.showYearPicker = false;
                            
                        }
                    } else {
                        this.showYearPicker = false;
                    }
                } else {
                    if (index.target.parentElement._prevClass != "cell" && index.target.parentElement._prevClass != "cell active" && index.target.parentElement._prevClass != "cell today active") {
                        this.showYearPicker = false;
                        this.$refs["ChooseTime"].style.display = "none";
                        this.$refs["ChooseTime"].style.visibility = "hidden";
                    }
                }
            }
        },
        handleClickOutsideRangePicker(index) {
            if (
                this.showRangePicker == true &&
                index.srcElement._prevClass != "optionTime rangeTime" &&
                index.srcElement._prevClass != "mx-icon-right" &&
                index.srcElement._prevClass != "mx-icon-left" &&
                index.srcElement._prevClass != "mx-icon-double-left" &&
                index.srcElement._prevClass != "mx-icon-double-right" &&
                index.srcElement._prevClass != "mx-calendar-header" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-right" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-right" &&
                index.srcElement._prevClass !=
                    "mx-calendar mx-calendar-panel-date"
            ) {
                if (navigator.userAgent.indexOf("Firefox") == -1) {
                    if (index.path.length == 15) {
                        if (index.path[9]._prevClass != "mx-datepicker-content") {
                            this.showRangePicker = false;
                            
                        }
                    } else {
                        this.showRangePicker = false;
                    }
                } else {
                    if (index.target.parentElement._prevClass != "cell" && index.target.parentElement._prevClass != "cell active" && index.target.parentElement._prevClass != "cell today active") {
                        this.showRangePicker = false;
                        this.$refs["ChooseTime"].style.display = "none";
                        this.$refs["ChooseTime"].style.visibility = "hidden";
                    }
                }
            }
        },
    },
    watch: {
        dateValue(val) {
            var compareToText = "So với ngày ";
            if (localStorage.lang == "en") {
                compareToText = "Compare to ";
            }
            this.compareType = "normal";
            this.compareText =
                compareToText +
                moment(val)
                    .subtract(1, "days")
                    .startOf("day")
                    .format("DD/MM/YYYY")
            this.dateRange = {
                dateStart: moment(val)
                    .startOf("day")
                    .format("X"),
                dateEnd: moment(val)
                    .startOf("day")
                    .format("X")
            };
            this.getDateRange(this.dateRange.dateStart, this.dateRange.dateEnd);
            this.dateRangeString = {
                dateStart: moment(val)
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                dateEnd: moment(val)
                    .startOf("day")
                    .format("DD/MM/YYYY")
            };
            this.$emit("changeDateRange", this.dateRange)
            this.$emit("changeDateRangeString", this.dateRange)
            this.$emit("changeCompareText", this.compareText)
            this.$emit("changeCompareType", this.compareType)

            var dataDate = {
                compareType: this.compareType,
                dateRange: this.dateRange
            }
            EventBus.$emit("changeDateShopingMall", dataDate);
            EventBus.$emit("changeDateRetailStore", dataDate);
            this.$refs["ChooseTime"].style.display = "none";
            this.$refs["ChooseTime"].style.visibility = "hidden";
        },
        weekValue(val) {
            this.compareType = "normal";
            this.compareText = this.$t("Report.CompareToLastWeek");
            this.dateRange = {
                dateStart: moment(val)
                    .startOf("week")
                    .startOf("day")
                    .format("X"),
                dateEnd: moment(val)
                    .endOf("week")
                    .startOf("day")
                    .format("X")
            };
            this.getDateRange(this.dateRange.dateStart, this.dateRange.dateEnd);
            this.dateRangeString = {
                dateStart: moment(val)
                    .startOf("week")
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                dateEnd: moment(val)
                    .endOf("week")
                    .startOf("day")
                    .format("DD/MM/YYYY")
            };
            this.$emit("changeDateRange", this.dateRange)
            this.$emit("changeDateRangeString", this.dateRange)
            this.$emit("changeCompareText", this.compareText)
            this.$emit("changeCompareType", this.compareType)
            
            var dataDate = {
                compareType: this.compareType,
                dateRange: this.dateRange
            }
            EventBus.$emit("changeDateShopingMall", dataDate);
            EventBus.$emit("changeDateRetailStore", dataDate);
            this.$refs["ChooseTime"].style.display = "none";
            this.$refs["ChooseTime"].style.visibility = "hidden";
        },
        monthValue(val) {
            this.compareType = "month";
            this.compareText = this.$t("Report.CompareToLastMonth");
            this.dateRange = {
                dateStart: moment(val)
                    .startOf("month")
                    .startOf("day")
                    .format("X"),
                dateEnd: moment(val)
                    .endOf("month")
                    .startOf("day")
                    .format("X")
            };
            this.getDateRange(this.dateRange.dateStart, this.dateRange.dateEnd);
            this.dateRangeString = {
                dateStart: moment(val)
                    .startOf("month")
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                dateEnd: moment(val)
                    .endOf("month")
                    .startOf("day")
                    .format("DD/MM/YYYY")
            };
            this.$emit("changeDateRange", this.dateRange)
            this.$emit("changeDateRangeString", this.dateRange)
            this.$emit("changeCompareText", this.compareText)
            this.$emit("changeCompareType", this.compareType)
            
            var dataDate = {
                compareType: this.compareType,
                dateRange: this.dateRange
            }
            EventBus.$emit("changeDateShopingMall", dataDate);
            EventBus.$emit("changeDateRetailStore", dataDate);
            this.$refs["ChooseTime"].style.display = "none";
            this.$refs["ChooseTime"].style.visibility = "hidden";
        },
        yearValue(val) {
            this.compareType = "year";
            this.compareText = this.$t("Report.CompareToLastYear");
            this.dateRange = {
                dateStart: moment(val)
                    .startOf("year")
                    .startOf("day")
                    .format("X"),
                dateEnd: moment(val)
                    .endOf("year")
                    .startOf("day")
                    .format("X")
            };
            this.getDateRange(this.dateRange.dateStart, this.dateRange.dateEnd);
            this.dateRangeString = {
                dateStart: moment(val)
                    .startOf("year")
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                dateEnd: moment(val)
                    .endOf("year")
                    .startOf("day")
                    .format("DD/MM/YYYY")
            };
            this.$emit("changeDateRange", this.dateRange)
            this.$emit("changeDateRangeString", this.dateRange)
            this.$emit("changeCompareText", this.compareText)
            this.$emit("changeCompareType", this.compareType)
            
            let dataDate = {
                compareType: this.compareType,
                dateRange: this.dateRange
            }
            EventBus.$emit("changeDateShopingMall", dataDate);
            EventBus.$emit("changeDateRetailStore", dataDate);
            this.$refs["ChooseTime"].style.display = "none";
            this.$refs["ChooseTime"].style.visibility = "hidden";
        },
        rangeTime(val) {
            this.$refs["ChooseTime"].style.display = "none";
            this.$refs["ChooseTime"].style.visibility = "hidden";
            this.compareType = "normal";
            var dateDelta =
                (moment(val[1])
                    .startOf("day")
                    .format("X") -
                    moment(val[0])
                        .startOf("day")
                        .format("X")) /
                    86400 +
                1;
            var compareToText = "So với ";
            var agoText = " ngày trước";
            if (localStorage.lang == "en") {
                compareToText = "Compare to ";
                agoText = " days ago";
            }
            this.compareText = compareToText + dateDelta + agoText;
            this.dateRange = {
                dateStart: moment(val[0])
                    .startOf("day")
                    .format("X"),
                dateEnd: moment(val[1])
                    .startOf("day")
                    .format("X")
            };
            this.getDateRange(this.dateRange.dateStart, this.dateRange.dateEnd);
            this.dateRangeString = {
                dateStart: moment(val[0])
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                dateEnd: moment(val[1])
                    .startOf("day")
                    .format("DD/MM/YYYY")
            };
            this.$emit("changeDateRange", this.dateRange)
            this.$emit("changeDateRangeString", this.dateRange)
            this.$emit("changeCompareText", this.compareText)
            
            var dataDate = {
                compareType: this.compareType,
                dateRange: this.dateRange
            }
            EventBus.$emit("changeDateShopingMall", dataDate);
            EventBus.$emit("changeDateRetailStore", dataDate);
            this.$refs["ChooseTime"].style.display = "none";
            this.$refs["ChooseTime"].style.visibility = "hidden";
        },
        showRangePicker(val) {
            if (val == true) {
                this.isCustomizeHover = true
            } else {
                this.isCustomizeHover = false
            }
        }
    }
}
</script>

<style lang="scss">
.line {
    border-top: solid 1px #bfbfbf;
}
.Btn-TimeSelect {
    background: #ffffff;
    border: 1px solid #434343;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #434343;
    position: relative;
    cursor: pointer;
}
.WrapChooseTime {
    background: #FFFFFF;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    display: flex;
    position: absolute;
    top: 46px;
    left: 0;
    z-index: 99;
    .TimePicker {
        padding: 16px;
        border-right: 1px solid #D9D9D9;
        display: flex;
        flex-direction: column;
        width: 168px;
        .optionTime { 
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            padding: 12px;
            cursor: pointer;
            &:hover {
                color: #FF4343;
            }
        }
    }
    .ShowValueTime {
        padding: 16px;
        min-width:312px;
        display: flex;
        flex-direction: column;
        position: relative;
        .ValueTime {
            width: 100%;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            padding: 12px;
            visibility: hidden;
            color: #FF4343;
        }
        .position {
            position: absolute;
            top: -16px;
        }
    }
    .ShowValueTimeWidth560 {
        width: 560px;
    }
}
</style>