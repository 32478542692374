<template>
    <div class="TableKPI">
        <div class="Wraptitle">
            <div class="titleAndSub">
                <div class="subTitle mt-8">
                    {{ dateRangeString.dateStart }} -
                    {{ dateRangeString.dateEnd }}
                    {{ $t("Heatmap.ComparedWith") }}
                    {{ dateRangePreviousString.dateStart }} -
                    {{ dateRangePreviousString.dateEnd }}
                </div>
            </div>
        </div>
        <table class="KPIstore mt-24">
            <tr>
                <th>
                    {{ $t("Report.StoreName") }}
                </th>
                <th
                    style="cursor:pointer"
                    @click="changeSortWay(!sortWayAscending)"
                >
                    {{ $t("dashboardShopingMall.passby") }}
                    <span
                        v-if="sortWayAscending == false"
                        class="material-icons"
                    >
                        arrow_drop_down
                    </span>
                    <span v-else class="material-icons"> arrow_drop_up </span>
                </th>
                <th
                    style="cursor:pointer"
                    @click="changeSortGate(!sortGateAscending)"
                >
                    {{ $t("dashboardShopingMall.footfall").toLocaleString() }}
                    <span
                        v-if="sortGateAscending == false"
                        class="material-icons"
                    >
                        arrow_drop_down
                    </span>
                    <span v-else class="material-icons"> arrow_drop_up </span>
                </th>
                <th
                    style="cursor:pointer"
                    @click="changeSortRate(!sortRateAscending)"
                >
                    {{ $t("dashboardShopingMall.rate") }}
                    <span
                        v-if="sortRateAscending == false"
                        class="material-icons"
                    >
                        arrow_drop_down
                    </span>
                    <span v-else class="material-icons"> arrow_drop_up </span>
                </th>
                <th
                    style="cursor:pointer"
                    @click="changeSortTime(!sortTimeAscending)"
                >
                    {{ $t("dashboardShopingMall.dwellTime") }}
                    <span
                        v-if="sortTimeAscending == false"
                        class="material-icons"
                    >
                        arrow_drop_down
                    </span>
                    <span v-else class="material-icons"> arrow_drop_up </span>
                </th>
            </tr>
            <tr
                v-for="store in storePerformanceDataPaginated"
                :key="store.name"
            >
                <td>
                    {{ store.name }}
                    <md-tooltip>{{ store.name }}</md-tooltip>
                </td>
                <td :class="store.colorClassWay">
                    {{ store.way.toLocaleString('en-US') }}
                    <div class="Balance">
                        <span v-if="store.wayPercentage >= 0" class="value"
                            >{{ store.wayPercentage }}%</span
                        >
                        <span v-if="store.wayPercentage < 0" class="valueDown"
                            >{{ store.wayPercentage }}%</span
                        >
                        <span
                            v-if="store.wayPercentage >= 0"
                            class="material-icons"
                            >arrow_drop_up</span
                        >
                        <span
                            v-if="store.wayPercentage < 0"
                            class="material-icons"
                            style="color: #FF5050"
                            >arrow_drop_down</span
                        >
                    </div>
                </td>
                <td :class="store.colorClassGate">
                    {{ store.gate.toLocaleString('en-US') }}
                    <div class="Balance">
                        <span v-if="store.gatePercentage >= 0" class="value"
                            >{{ store.gatePercentage }}%</span
                        >
                        <span v-if="store.gatePercentage < 0" class="valueDown"
                            >{{ store.gatePercentage }}%</span
                        >
                        <span
                            v-if="store.gatePercentage >= 0"
                            class="material-icons"
                            >arrow_drop_up</span
                        >
                        <span
                            v-if="store.gatePercentage < 0"
                            class="material-icons"
                            style="color: #FF5050"
                            >arrow_drop_down</span
                        >
                    </div>
                </td>
                <td :class="store.colorClassRate">
                    {{ store.rate }}%
                    <div class="Balance">
                        <span v-if="store.ratePercentage >= 0" class="value"
                            >{{ store.ratePercentage }}%</span
                        >
                        <span v-if="store.ratePercentage < 0" class="valueDown"
                            >{{ store.ratePercentage }}%</span
                        >
                        <span
                            v-if="store.ratePercentage >= 0"
                            class="material-icons"
                            >arrow_drop_up</span
                        >
                        <span
                            v-if="store.ratePercentage < 0"
                            class="material-icons"
                            style="color: #FF5050"
                            >arrow_drop_down</span
                        >
                    </div>
                </td>
                <td :class="store.colorClassTimeAverage">
                    {{ store.timeAverageTransform }}
                    <div class="Balance">
                        <span
                            v-if="store.timeAveragePercentage >= 0"
                            class="value"
                            >{{ store.timeAveragePercentage }}%</span
                        >
                        <span
                            v-if="store.timeAveragePercentage < 0"
                            class="valueDown"
                            >{{ store.timeAveragePercentage }}%</span
                        >
                        <span
                            v-if="store.timeAveragePercentage >= 0"
                            class="material-icons"
                            >arrow_drop_up</span
                        >
                        <span
                            v-if="store.timeAveragePercentage < 0"
                            class="material-icons"
                            style="color: #FF5050"
                            >arrow_drop_down</span
                        >
                    </div>
                </td>
            </tr>
        </table>

        <div class="WrapNumber mt-auto">
            <div class="Back" @click="turnPage('backward')">
                <span class="material-icons">navigate_before</span>
            </div>
            <div
                class="Number"
                @click="turnPage('backward')"
                v-if="page - 1 >= 1"
            >
                {{ page - 1 }}
            </div>
            <div class="Number" style="font-weight:bold">{{ page }}</div>
            <div
                class="Number"
                @click="turnPage('forward')"
                v-if="page + 1 <= totalPages"
            >
                {{ page + 1 }}
            </div>
            <div class="Next" @click="turnPage('forward')">
                <span class="material-icons">navigate_next</span>
            </div>
        </div>
    </div>
</template>
<script>
import EventBus from "@/EventBus";

import * as moment from "moment-timezone";
import "moment/locale/vi";

moment.tz.setDefault("Asia/Ho_Chi_Minh");
moment.locale("vi");

export default {
    name: "KpiTableReport",
    mounted() {
        var self = this;
        EventBus.$on("changeDateShopingMall", data => {
            self.dateRangeForTable = data;
            if (data.compareType == "normal" || data.compareType == "week") {
                var dateDelta =
                    data.dateRange.dateEnd - data.dateRange.dateStart + 86400;
                this.dateRangeString = {
                    dateStart: moment
                        .unix(data.dateRange.dateStart)
                        .format("DD/MM/YYYY"),
                    dateEnd: moment
                        .unix(data.dateRange.dateEnd)
                        .format("DD/MM/YYYY")
                };
                this.dateRangePreviousString = {
                    dateStart: moment
                        .unix(data.dateRange.dateStart - dateDelta)
                        .format("DD/MM/YYYY"),
                    dateEnd: moment
                        .unix(data.dateRange.dateEnd - dateDelta)
                        .format("DD/MM/YYYY")
                };
            } else if (data.compareType == "month") {
                this.dateRangeString = {
                    dateStart: moment
                        .unix(data.dateRange.dateStart)
                        .format("DD/MM/YYYY"),
                    dateEnd: moment
                        .unix(data.dateRange.dateEnd)
                        .format("DD/MM/YYYY")
                };
                this.dateRangePreviousString = {
                    dateStart: moment
                        .unix(data.dateRange.dateStart - 1000)
                        .startOf("month")
                        .format("DD/MM/YYYY"),
                    dateEnd: moment
                        .unix(data.dateRange.dateStart - 1000)
                        .endOf("month")
                        .format("DD/MM/YYYY")
                };
            } else if (data.compareType == "year") {
                this.dateRangeString = {
                    dateStart: moment
                        .unix(data.dateRange.dateStart)
                        .format("DD/MM/YYYY"),
                    dateEnd: moment
                        .unix(data.dateRange.dateEnd)
                        .format("DD/MM/YYYY")
                };
                this.dateRangePreviousString = {
                    dateStart: moment
                        .unix(data.dateRange.dateStart - 1000)
                        .startOf("year")
                        .format("DD/MM/YYYY"),
                    dateEnd: moment
                        .unix(data.dateRange.dateStart - 1000)
                        .endOf("year")
                        .format("DD/MM/YYYY")
                };
            }
        });
    },
    props: {
        rawDataForTable: Array
    },
    data() {
        return {
            dateRangeForTable: {},
            storePerformanceData: [],
            page: 1,
            totalPages: 1,
            storePerPage: 5,

            sortWayAscending: false,
            sortGateAscending: false,
            sortRateAscending: false,
            sortTimeAscending: false,
            ascending: false,
            sortAtribute: "",
            isDashboard: false,

            dateRangeString: {
                dateStart: moment()
                    .subtract(7, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                dateEnd: moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY")
            },
            dateRangePreviousString: {
                dateStart: moment()
                    .subtract(8, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                dateEnd: moment()
                    .subtract(14, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY")
            }
        };
    },

    methods: {
        makeData() {
            var maxWay = 0;
            var maxComeIn = 0;
            var maxRate = 0;
            var maxDwellTime = 0;
            var rawDataStorePerformanceData = [];

            this.totalPages = Math.ceil(
                this.rawDataForTable.length / this.storePerPage
            );
            for (let i = 0; i < this.rawDataForTable.length; i++) {
                var shop = this.rawDataForTable[i];

                // check 1000001
                if (shop.gatePercentage == 100000001) {
                    shop.gatePercentage = 0;
                }
                if (shop.rate == 100000001) {
                    shop.rate = 0;
                }
                if (shop.ratePercentage == 100000001) {
                    shop.ratePercentage = 0;
                }
                if (shop.timeAverage == 100000001) {
                    shop.timeAverage = 0;
                }
                if (shop.timeAveragePercentage == 100000001) {
                    shop.timeAveragePercentage = 0;
                }
                if (shop.wayPercentage == 100000001) {
                    shop.wayPercentage = 0;
                }

                // add plus
                if (shop.ratePercentage > 0) {
                    shop.ratePercentage = "+" + shop.ratePercentage;
                }
                if (shop.gatePercentage > 0) {
                    shop.gatePercentage = "+" + shop.gatePercentage;
                }
                if (shop.wayPercentage > 0) {
                    shop.wayPercentage = "+" + shop.wayPercentage;
                }
                if (shop.timeAveragePercentage > 0) {
                    shop.timeAveragePercentage =
                        "+" + shop.timeAveragePercentage;
                }

                // find max of each attribute
                if (shop.gate > maxComeIn) {
                    maxComeIn = shop.gate;
                }
                if (shop.way > maxWay) {
                    maxWay = shop.way;
                }
                if (shop.timeAverage > maxDwellTime) {
                    maxDwellTime = shop.timeAverage;
                }
                if (shop.rate > maxRate) {
                    maxRate = shop.rate;
                }

                // transform dwellTime unit
                var dwellTime = "";

                var hour = parseInt(shop.timeAverage / 3600);
                if (hour != 0) {
                    dwellTime = hour + "h";
                }
                var redundantHour = shop.timeAverage % 3600;
                var minutes = parseInt(redundantHour / 60);
                var seconds = parseInt(((redundantHour / 60) - parseInt(redundantHour / 60)) * 60)
                if (minutes != 0) {
                    if (localStorage.lang == null || localStorage.lang == "vn") {
                        shop.timeAverageTransform = dwellTime + minutes + "p" + seconds + "s";
                    } else {
                        shop.timeAverageTransform = dwellTime + minutes + "m" + seconds + "s";
                    }
                } else {
                    shop.timeAverageTransform = dwellTime + seconds + "s";
                }

                rawDataStorePerformanceData.push(shop);
            }
            // tô màu
            for (var i = 0; i < rawDataStorePerformanceData.length; i++) {
                // way
                rawDataStorePerformanceData[
                    i
                ].colorClassGate = this.chooseColor(
                    rawDataStorePerformanceData[i].gate,
                    maxComeIn
                );
                rawDataStorePerformanceData[i].colorClassWay = this.chooseColor(
                    rawDataStorePerformanceData[i].way,
                    maxWay
                );
                rawDataStorePerformanceData[
                    i
                ].colorClassTimeAverage = this.chooseColor(
                    rawDataStorePerformanceData[i].timeAverage,
                    maxDwellTime
                );
                rawDataStorePerformanceData[
                    i
                ].colorClassRate = this.chooseColor(
                    rawDataStorePerformanceData[i].rate,
                    maxRate
                );
            }

            this.storePerformanceData = rawDataStorePerformanceData;
            this.changeSortGate(false)
        },
        changeSortWay(sortWayAscending) {
            this.ascending = sortWayAscending;
            this.sortWayAscending = sortWayAscending;
            this.sortGateAscending = false;
            this.sortRateAscending = false;
            this.sortTimeAscending = false;
            this.sortAtribute = "way";
        },
        changeSortGate(sortGateAscending) {
            this.ascending = sortGateAscending;
            this.sortGateAscending = sortGateAscending;
            this.sortWayAscending = false;
            this.sortRateAscending = false;
            this.sortTimeAscending = false;
            this.sortAtribute = "gate";
        },
        changeSortRate(sortRateAscending) {
            this.ascending = sortRateAscending;
            this.sortRateAscending = sortRateAscending;
            this.sortWayAscending = false;
            this.sortGateAscending = false;
            this.sortTimeAscending = false;
            this.sortAtribute = "rate";
        },
        changeSortTime(sortTimeAscending) {
            this.ascending = sortTimeAscending;
            this.sortTimeAscending = sortTimeAscending;
            this.sortWayAscending = false;
            this.sortGateAscending = false;
            this.sortRateAscending = false;
            this.sortAtribute = "timeAverage";
        },

        turnPage(position) {
            if (position == "forward") {
                if (this.page < this.totalPages) {
                    this.page = this.page + 1;
                }
            } else if (position == "backward") {
                if (this.page > 1) {
                    this.page = this.page - 1;
                }
            }
        },
        chooseColor(value, maxValue) {
            if (value <= (maxValue / 5) * 2) {
                return "lowestColor";
            } else if (
                value > (maxValue / 5) * 2 &&
                value <= (maxValue / 5) * 3
            ) {
                return "lowColor";
            } else if (
                value > (maxValue / 5) * 3 &&
                value <= (maxValue / 5) * 4
            ) {
                return "highColor";
            } else {
                return "highestColor";
            }
        },
        dynamicSort(property) {
            var sortOrder = 1;
            if (this.ascending == false) {
                sortOrder = -1;
            }
            return function(a, b) {
                var result =
                    a[property] < b[property]
                        ? -1
                        : a[property] > b[property]
                        ? 1
                        : 0;
                return result * sortOrder;
            };
        }
    },
    computed: {
        storePerformanceDataPaginated() {
            return this.storePerformanceData.slice(
                (this.page - 1) * this.storePerPage,
                this.page * this.storePerPage
            );
        }
    },
    watch: {
        sortAtribute(val) {
            this.storePerformanceData.sort(this.dynamicSort(val));
        },
        ascending(val) {
            this.storePerformanceData.sort(this.dynamicSort(this.sortAtribute));
        },
        rawDataForTable(val) {
            this.makeData();
        }
    }
};
</script>
<style lang="scss">
.TableKPI {
    .Wraptitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .titleAndSub {
            display: flex;
            flex-direction: column;
            .titleTable {
                font-family: "Roboto";
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: #1f1f1f;
                text-transform: uppercase;
            }
            .subTitle {
                font-family: "Roboto";
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #5c6066;
            }
        }
    }
    .KPIstore {
        border-spacing: 0px;
        width: 100%;
        border: 1px solid #e7e7ed;
        margin-bottom: 48px;
        th {
            height: 56px;
            border-right: 1px solid #e7e7ed;
            border-bottom: 1px solid #e7e7ed;
            background-color: #d9d9d9;
            vertical-align: middle;
            font-family: "Roboto";
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #272833;
            .material-icons {
                vertical-align: middle;
                cursor: pointer;
            }
        }
        th:last-child {
            border-right: none;
        }
        td:last-child {
            border-right: none;
        }
        td {
            border-bottom: 1px solid #e7e7ed;
            text-align: center;
            height: 64px;
            border-right: 1px solid #e7e7ed;
            font-family: "Roboto";
            font-weight: 800;
            vertical-align: middle;
            font-size: 20px;
            line-height: 24px;
            color: #ffffff;
            padding: 8px 12px;
            position: relative;
            .Balance {
                position: absolute;
                right: 0;
                bottom: 0;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .value {
                    font-family: "Roboto";
                    font-weight: 800;
                    font-size: 16px;
                    line-height: 24px;
                    text-shadow: 2px 2px 2px rgba(38, 9, 120, 0.5);
                    color: #2bff00;
                }
                .valueDown {
                    font-family: "Roboto";
                    font-weight: 800;
                    font-size: 16px;
                    line-height: 24px;
                    text-shadow: 2px 2px 2px rgba(38, 9, 120, 0.5);
                    color: #ff5050;
                }
                .material-icons {
                    color: #2bff00;
                }
            }
            img {
                width: 48px;
                height: 48px;
                border-radius: 4px;
            }
        }
        td:first-child {
            background-color: #eaeaea;
            font-family: "Roboto";
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: #1f1f1f;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 160px;
        }
        .highestColor {
            background-color: #104579;
        }
        .highColor {
            background-color: #43688C;
        }
        .mediumColor {
            background-color: #5C8EBF;
        }
        .lowColor {
            background-color: #68A1D9;
        }
        .lowestColor {
            background-color: #7BBEFF;
        }
    }
    .WrapNumber {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        bottom: 24px;
        right: 24px;
        .Back {
            height: 24px;
            cursor: pointer;
            .material-icons {
                border: 2px solid #f0f0f0;
                border-radius: 4px;
                background: #f7f9fc;
            }
        }
        .Number {
            height: 24px;
            width: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        .Next {
            height: 24px;
            cursor: pointer;
            .material-icons {
                border: 2px solid #f0f0f0;
                border-radius: 4px;
            }
        }
    }
}

@media only screen and (max-width: 1280px) {
    .TableKPI {
        
        .KPIstore {
            td {
                .Balance {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    .value {
                        font-family: "Roboto";
                        font-weight: 800;
                        font-size: 12px;
                        line-height: 24px;
                        text-shadow: 2px 2px 2px rgba(38, 9, 120, 0.5);
                        color: #2bff00;
                    }
                    .valueDown {
                        font-family: "Roboto";
                        font-weight: 800;
                        font-size: 12px;
                        line-height: 24px;
                        text-shadow: 2px 2px 2px rgba(38, 9, 120, 0.5);
                        color: #FF5361;
                    }
                }
            }

            Time {
                display: inline-block;
                max-width: 60px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                
            }
        }
    }
}

@media only screen and (max-width: 1100px) {
    .TableKPI {
        
        .KPIstore {
            td {
                .Balance {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    .value {
                        font-family: "Roboto";
                        font-weight: 800;
                        font-size: 8px;
                        line-height: 24px;
                        text-shadow: 2px 2px 2px rgba(38, 9, 120, 0.5);
                        color: #2bff00;
                    }
                    .valueDown {
                        font-family: "Roboto";
                        font-weight: 800;
                        font-size: 8px;
                        line-height: 24px;
                        text-shadow: 2px 2px 2px rgba(38, 9, 120, 0.5);
                        color: #FF5361;
                    }
                }
            }

            Time {
                display: inline-block;
                max-width: 60px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                
            }
        }
    }
}
</style>
