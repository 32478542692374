<template>
    <div>
        <table class="TableReportCustomer">
            <tr>
                <th>{{ $t('Customers.Time') }}</th>
                <th>Live</th>
                <th>{{ $t('dashboard.Name') }}</th>
                <th>{{ $t('Customers.Segment') }}</th>
                <th>{{ $t('Customers.StoreAddress') }}</th>
                <th>{{ $t('Customers.NumberOfVisits') }}</th>
            </tr>
            <tr
                v-for="customerRecord in listCustomerRecordData"
                :key="customerRecord.id"
            >
                <td>{{ convertTimestampToString(customerRecord.timeVisit) }}</td>
                <td>
                    <img
                        :src="customerRecord.currentImage"
                        alt="Italian Trulli"
                    />
                </td>
                <td>
                    <img
                        :src="customerRecord.image"
                        alt="Italian Trulli"
                        @error="handleErrorLoadImage"
                    />
                    {{ getCustomerName(customerRecord.customerName) }}
                </td>
                <td>{{ customerRecord.customerType }}</td>
                <td>{{ customerRecord.groupName }}</td>
                <td>{{ customerRecord.visitNumber }}</td>
            </tr>
        </table>

        <div class="paginationDasboard mt-16">
            <div class="WrapNumber">
                <div
                    class="Back"
                    v-if="page != 1"
                    style="cursor: pointer"
                    @click="changePage('first')"
                >
                    <span class="material-icons">first_page</span>
                </div>
                <div
                    class="Back"
                    v-if="page != 1"
                    style="cursor: pointer"
                    @click="changePage(-1)"
                >
                    <span class="material-icons">navigate_before</span>
                </div>
                <div
                    class="Number"
                    v-if="page != 1"
                    style="cursor: pointer"
                    @click="changePage(-1)"
                >
                    {{ page - 1 }}
                </div>
                <div class="Number">{{ page }}</div>
                <div
                    class="Number"
                    style="cursor: pointer"
                    @click="changePage(1)"
                    v-if="page != totalPage"
                >
                    {{ page + 1 }}
                </div>
                <div
                    class="Next"
                    v-if="page != totalPage"
                    style="cursor: pointer"
                    @click="changePage(1)"
                >
                    <span class="material-icons">navigate_next</span>
                </div>
                <div
                    class="Next"
                    v-if="page != totalPage"
                    style="cursor: pointer"
                    @click="changePage('last')"
                >
                    <span class="material-icons">last_page</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as moment from 'moment-timezone';
import { Api } from '@/api/index';

moment.tz.setDefault('Asia/Ho_Chi_Minh');
moment.locale('vi');

const listCustomerRecordAPI = Api.get('getCustomerRecordV2');

export default {
    data() {
        return {
            listCustomerRecordData: [],
            totalPage: 1,
            dateStart: moment().startOf('day').format('X'),
            dateEnd: moment().startOf('day').format('X'),
            page: 1,
        };
    },
    props: {
        groupName: {
            type: String,
            default: '',
        },
        retail: {
            type: String,
        },
    },
    mounted() {
        if (this.retail === 'true') {
            this.callGetListCustomerRecord(
                this.dateStart,
                this.dateEnd,
                '',
                'timestamp',
                'false',
                '',
                this.groupName,
                this.page,
                6,
                'false'
            );
        } else {
            this.callGetListCustomerRecord(
                this.dateStart,
                this.dateEnd,
                '',
                'timestamp',
                'false',
                '',
                this.groupName,
                this.page,
                5,
                'true'
            );
        }
    },
    watch: {
        groupName(val) {
            if (this.retail === 'true') {
                this.callGetListCustomerRecord(
                    this.dateStart,
                    this.dateEnd,
                    '',
                    'timestamp',
                    'false',
                    '',
                    val,
                    this.page,
                    6,
                    'false'
                );
            } else {
                this.callGetListCustomerRecord(
                    this.dateStart,
                    this.dateEnd,
                    '',
                    'timestamp',
                    'false',
                    '',
                    val,
                    this.page,
                    5,
                    'true'
                );
            }
        },
    },
    methods: {
        handleErrorLoadImage(e) {
            e.target.src = '/img/default-avatar.png';
        },
        convertTimestampToString(timestamp) {
            const lang = localStorage.lang;
            const dayOfWeek = {
                1: lang === 'vn' ? 'Thứ 2 ngày' : 'Monday',
                2: lang === 'vn' ? 'Thứ 3 ngày' : 'Tuesday',
                3: lang === 'vn' ? 'Thứ 4 ngày' : 'Wednesday',
                4: lang === 'vn' ? 'Thứ 5 ngày' : 'Thursday',
                5: lang === 'vn' ? 'Thứ 6 ngày' : 'Friday',
                6: lang === 'vn' ? 'Thứ 7 ngày' : 'Saturday',
                7: lang === 'vn' ? 'Chủ nhật ngày' : 'Sunday',
            };
            const dateTimeObject = moment.unix(timestamp).locale('vi');
            return `${dateTimeObject.format('HH:mm')}, ${
                dayOfWeek[dateTimeObject.isoWeekday()]
            } ${dateTimeObject.format('DD/MM/YYYY')}`;
        },
        getCustomerName(customerName) {
            if (!customerName) {
                const lang = localStorage.lang;
                return lang === 'vn' ? 'Khách mới' : 'New Customer';
            }
            return customerName;
        },
        callGetListCustomerRecord(
            dateStart,
            dateEnd,
            searchValue,
            sortAtribute,
            ascending,
            customerTypeFilter,
            groupNameFilter,
            page,
            limit,
            familiarCustomer
        ) {
            console.log(limit, page)
            listCustomerRecordAPI
                .get(
                    dateStart,
                    dateEnd,
                    searchValue,
                    sortAtribute,
                    ascending,
                    customerTypeFilter,
                    groupNameFilter,
                    page,
                    limit,
                    'false',
                    familiarCustomer
                )
                .then((response) => {
                    var listCustomerRecordData = response.data.listResponse;
                    for (let i = 0; i < listCustomerRecordData.length; i++) {
                        if (listCustomerRecordData[i].image == '') {
                            listCustomerRecordData[i].image = '/img/default-avatar.png';
                        }
                    }
                    this.listCustomerRecordData = listCustomerRecordData;
                    if (response.data.totalPage === 0) {
                        this.totalPage = 1
                    } else {
                        this.totalPage = response.data.totalPage;
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.totalPage = 1;
                });
        },
        changePage(number) {
            if (number == 'first') {
                this.page = 1;
                if (this.retail == 'true') {
                    this.callGetListCustomerRecord(
                        this.dateStart,
                        this.dateEnd,
                        '',
                        'timestamp',
                        'false',
                        '',
                        this.groupName,
                        this.page,
                        6,
                        'false',
                        'false'
                    );
                } else {
                    this.callGetListCustomerRecord(
                        this.dateStart,
                        this.dateEnd,
                        '',
                        'timestamp',
                        'false',
                        '',
                        this.groupName,
                        this.page,
                        5,
                        'false',
                        'true'
                    );
                }
            }
            if (number == 'last') {
                this.page = this.totalPage;
                if (this.retail == 'true') {
                    this.callGetListCustomerRecord(
                        this.dateStart,
                        this.dateEnd,
                        '',
                        'timestamp',
                        'false',
                        '',
                        this.groupName,
                        this.page,
                        6,
                        'false',
                        'false'
                    );
                } else {
                    this.callGetListCustomerRecord(
                        this.dateStart,
                        this.dateEnd,
                        '',
                        'timestamp',
                        'false',
                        '',
                        this.groupName,
                        this.page,
                        5,
                        'false',
                        'true'
                    );
                }
            }
            if (number == -1 && this.page != 1) {
                this.page--;
                if (this.retail == 'true') {
                    this.callGetListCustomerRecord(
                        this.dateStart,
                        this.dateEnd,
                        '',
                        'timestamp',
                        'false',
                        '',
                        this.groupName,
                        this.page,
                        6,
                        'false',
                        'false'
                    );
                } else {
                    this.callGetListCustomerRecord(
                        this.dateStart,
                        this.dateEnd,
                        '',
                        'timestamp',
                        'false',
                        '',
                        this.groupName,
                        this.page,
                        5,
                        'false',
                        'true'
                    );
                }
            }
            if (number == 1 && this.page < this.totalPage) {
                this.page++;
                if (this.retail == 'true') {
                    this.callGetListCustomerRecord(
                        this.dateStart,
                        this.dateEnd,
                        '',
                        'timestamp',
                        'false',
                        '',
                        this.groupName,
                        this.page,
                        6,
                        'false',
                        'false'
                    );
                } else {
                    this.callGetListCustomerRecord(
                        this.dateStart,
                        this.dateEnd,
                        '',
                        'timestamp',
                        'false',
                        '',
                        this.groupName,
                        this.page,
                        5,
                        'false',
                        'true'
                    );
                }
            }
        },
    },
};
</script>
<style lang="scss">
.TableReportCustomer {
    border-spacing: 0px;
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #d9d9d9;
    tr {
        background-color: #fff;
    }
    th {
        height: 56px;
        border-bottom: 1px solid #d9d9d9;
        background-color: #064b6c;
        color: #fff;
        vertical-align: middle;
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        color: #ffffff;
        .material-icons {
            vertical-align: middle;
            cursor: pointer;
        }
    }
    th:last-child {
        border-right: none;
    }
    td {
        border-bottom: 1px solid #e7e7ed;
        text-align: center;
        height: 48px;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #1f1f1f;
        padding: 13px 12px;
        img {
            width: 48px;
            height: 48px;
            border-radius: 4px;
        }
    }
    td:nth-child(3) {
        text-align: left;
        img {
            margin-right: 8px;
            margin-left: 15px;
            border-radius: 50%;
        }
    }
}
.paginationDasboard {
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    font-weight: 400;
    width: 100%;
    justify-content: flex-end;
    font-size: 14px;
    line-height: 20px;
    color: #8c8c8c;
    height: 24px;
    .SelectNumber {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        .WrapOption {
            background: #fff;
            max-height: 320px;
            overflow: auto;
            box-sizing: border-box;
            box-shadow: 0 2px 16px rgb(0 0 0 / 5%);
            border-radius: 8px;
            position: absolute;
            top: 26px;
            z-index: 9999;
            visibility: hidden;
            .Option {
                padding: 16px;
                font-family: Roboto;
                font-size: 14px;
                color: #272833;
                font-weight: 400;
                cursor: pointer;
                &:hover {
                    background-color: #edf3ff;
                }
            }
        }
        &:hover {
            .WrapOption {
                visibility: visible;
            }
        }
    }
    .WrapNumber {
        display: flex;
        align-items: center;
        .Back {
            height: 24px;
        }
        .Number {
            height: 24px;
            width: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .Next {
            height: 24px;
        }
    }
}
.ml-8 {
    margin-left: 8px;
}
.mt-16 {
    margin-top: 16px;
}
</style>
