<template>
    <div class="TableKPIRetailStore">
        <div class="Wraptitle" ref="Wraptitle">
            <div class="titleAndSub">
                <span class="titleTable">{{ title }}</span>
                <div class="subTitle" v-if="isReport == 'true'">
                    {{ dateRangeString.dateStart }} -
                    {{ dateRangeString.dateEnd }}
                    {{ $t('Heatmap.ComparedWith') }}
                    {{ dateRangePreviousString.dateStart }} -
                    {{ dateRangePreviousString.dateEnd }}
                </div>
            </div>
            <div class="WrapNumber">
                <div class="Back" v-if="page - 1 >= 1" @click="turnPage('first')">
                    <span class="material-icons">first_page</span>
                </div>
                <div class="Back" v-if="page - 1 >= 1" @click="turnPage('backward')">
                    <span class="material-icons">navigate_before</span>
                </div>
                <div class="Number" @click="turnPage('backward')" v-if="page - 1 >= 1">
                    {{ page - 1 }}
                </div>
                <div class="Number" style="font-weight:bold; color: #1F1F1F">{{ page }}</div>
                <div class="Number" @click="turnPage('forward')" v-if="page + 1 <= totalPages">
                    {{ page + 1 }}
                </div>
                <div class="Next" v-if="page + 1 <= totalPages" @click="turnPage('forward')">
                    <span class="material-icons">navigate_next</span>
                </div>
                <div class="Next" v-if="page + 1 <= totalPages" @click="turnPage('last')">
                    <span class="material-icons">last_page</span>
                </div>
            </div>
        </div>
        <table class="KPIstore mt-8">
            <tr>
                <th>
                    {{ $t('Report.StoreName') }}
                </th>
                <th style="cursor:pointer" @click="changeSortGate(!sortGateAscending)">
                    {{ $t('dashboard.Footfall') }}
                    <span v-if="sortGateAscending == false" class="material-icons">
                        arrow_drop_down
                    </span>
                    <span v-else class="material-icons"> arrow_drop_up </span>
                </th>
                <th style="cursor:pointer" @click="changeSortShop(!sortShopAscending)">
                    {{ $t('dashboard.Transactions') }}
                    <span v-if="sortShopAscending == false" class="material-icons">
                        arrow_drop_down
                    </span>
                    <span v-else class="material-icons"> arrow_drop_up </span>
                </th>
                <th style="cursor:pointer" @click="changeSortRate(!sortRateAscending)">
                    {{ $t('dashboardShopingMall.rate') }}
                    <span v-if="sortRateAscending == false" class="material-icons">
                        arrow_drop_down
                    </span>
                    <span v-else class="material-icons"> arrow_drop_up </span>
                </th>
                <th style="cursor:pointer" @click="changeSortTime(!sortTimeAscending)">
                    {{ $t('dashboard.Dwelltime') }}
                    <span v-if="sortTimeAscending == false" class="material-icons">
                        arrow_drop_down
                    </span>
                    <span v-else class="material-icons"> arrow_drop_up </span>
                </th>
            </tr>
            <tr v-for="(store, index) in storePerformanceDataPaginated" :key="index">
                <td>
                    {{ store.name }}
                    <md-tooltip>{{ store.name }}</md-tooltip>
                </td>
                <td :class="store.colorClassGate">
                    {{ store.gate.toLocaleString('en-US') }}
                    <div class="Balance">
                        <span v-if="store.gatePercentage >= 0" class="value">{{ store.gatePercentage }}%</span>
                        <span v-if="store.gatePercentage < 0" class="valueDown"
                            >{{ Math.abs(store.gatePercentage) }}%</span
                        >
                        <span v-if="store.gatePercentage >= 0" class="material-icons">arrow_drop_up</span>
                        <span v-if="store.gatePercentage < 0" class="material-icons" style="color: #FF5361"
                            >arrow_drop_down</span
                        >
                    </div>
                </td>
                <td :class="store.colorClassShop">
                    {{ store.shop.toLocaleString('en-US') }}
                    <div class="Balance">
                        <span v-if="store.shopPercentage >= 0" class="value">{{ store.shopPercentage }}%</span>
                        <span v-if="store.shopPercentage < 0" class="valueDown"
                            >{{ Math.abs(store.shopPercentage) }}%</span
                        >
                        <span v-if="store.shopPercentage >= 0" class="material-icons">arrow_drop_up</span>
                        <span v-if="store.shopPercentage < 0" class="material-icons" style="color: #FF5361"
                            >arrow_drop_down</span
                        >
                    </div>
                </td>
                <td :class="store.colorClassRate">
                    {{ store.rate }}%
                    <div class="Balance">
                        <span v-if="store.ratePercentage >= 0" class="value">{{ store.ratePercentage }}%</span>
                        <span v-if="store.ratePercentage < 0" class="valueDown"
                            >{{ Math.abs(store.ratePercentage) }}%</span
                        >
                        <span v-if="store.ratePercentage >= 0" class="material-icons">arrow_drop_up</span>
                        <span v-if="store.ratePercentage < 0" class="material-icons" style="color: #FF5361"
                            >arrow_drop_down</span
                        >
                    </div>
                </td>
                <td :class="store.colorClassTimeAverage">
                    <span class="Time">
                        {{ store.timeAverageTransform }}
                        <md-tooltip>{{ store.timeAverageTransform }}</md-tooltip>
                    </span>
                    <div class="Balance">
                        <span v-if="store.timeAveragePercentage >= 0" class="value"
                            >{{ store.timeAveragePercentage }}%</span
                        >
                        <span v-if="store.timeAveragePercentage < 0" class="valueDown"
                            >{{ Math.abs(store.timeAveragePercentage) }}%</span
                        >
                        <span v-if="store.timeAveragePercentage >= 0" class="material-icons">arrow_drop_up</span>
                        <span v-if="store.timeAveragePercentage < 0" class="material-icons" style="color: #FF5361"
                            >arrow_drop_down</span
                        >
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
import { Api } from '@/api/index';
import utils from '@/utils';
import EventBus from '@/EventBus';
import * as moment from 'moment-timezone';
import 'moment/locale/vi';

moment.tz.setDefault('Asia/Ho_Chi_Minh');
moment.locale('vi');

const storePerformanceRetailStore = Api.get('storePerformanceRetailStore');
const storePerformanceReportRetailStore = Api.get('storePerformanceReportRetailStore');

export default {
    props: {
        isReport: {
            type: String,
        },
    },
    mounted() {
        let self = this;
        EventBus.$on('changeDateRetailStore', data => {
            self.dateRange = data.dateRange;
            self.compareType = data.compareType;

            if (data.compareType == 'normal' || data.compareType == 'week') {
                let dateDelta = data.dateRange.dateEnd - data.dateRange.dateStart + 86400;
                this.dateRangeString = {
                    dateStart: moment.unix(data.dateRange.dateStart).format('DD/MM/YYYY'),
                    dateEnd: moment.unix(data.dateRange.dateEnd).format('DD/MM/YYYY'),
                };
                this.dateRangePreviousString = {
                    dateStart: moment.unix(data.dateRange.dateStart - dateDelta).format('DD/MM/YYYY'),
                    dateEnd: moment.unix(data.dateRange.dateEnd - dateDelta).format('DD/MM/YYYY'),
                };
            } else if (data.compareType == 'month') {
                this.dateRangeString = {
                    dateStart: moment.unix(data.dateRange.dateStart).format('DD/MM/YYYY'),
                    dateEnd: moment.unix(data.dateRange.dateEnd).format('DD/MM/YYYY'),
                };
                this.dateRangePreviousString = {
                    dateStart: moment
                        .unix(data.dateRange.dateStart - 1000)
                        .startOf('month')
                        .format('DD/MM/YYYY'),
                    dateEnd: moment
                        .unix(data.dateRange.dateStart - 1000)
                        .endOf('month')
                        .format('DD/MM/YYYY'),
                };
            } else if (data.compareType == 'year') {
                this.dateRangeString = {
                    dateStart: moment.unix(data.dateRange.dateStart).format('DD/MM/YYYY'),
                    dateEnd: moment.unix(data.dateRange.dateEnd).format('DD/MM/YYYY'),
                };
                this.dateRangePreviousString = {
                    dateStart: moment
                        .unix(data.dateRange.dateStart - 1000)
                        .startOf('year')
                        .format('DD/MM/YYYY'),
                    dateEnd: moment
                        .unix(data.dateRange.dateStart - 1000)
                        .endOf('year')
                        .format('DD/MM/YYYY'),
                };
            }
            storePerformanceReportRetailStore
                .get('', this.dateRange.dateStart, this.dateRange.dateEnd, this.compareType)
                .then(response => {
                    this.processData(response.data.data);
                });
        });
        this.title = this.$t('dashboard.TitleForKPITable');
        if (this.isReport == 'true') {
            storePerformanceReportRetailStore
                .get('', this.dateRange.dateStart, this.dateRange.dateEnd, this.compareType)
                .then(response => {
                    this.processData(response.data.data);
                });
        } else {
            this.$refs['Wraptitle'].style.marginBottom = '32px';
            storePerformanceRetailStore.get().then(response => {
                this.processData(response.data.data);
            });
        }
    },
    data() {
        let self = this;
        return {
            title: self.$t('dashboardShopingMall.titleForShopKPI'),
            storePerformanceData: [],
            page: 1,
            totalPages: 1,
            storePerPage: 5,

            sortGateAscending: false,
            sortShopAscending: false,
            sortRateAscending: false,
            sortTimeAscending: false,
            ascending: false,
            sortAtribute: '',
            isDashboard: false,
            dateRange: {
                dateStart: moment()
                    .subtract(7, 'day')
                    .startOf('day')
                    .format('X'),
                dateEnd: moment()
                    .subtract(1, 'day')
                    .startOf('day')
                    .format('X'),
            },
            compareType: 'normal',
            dateRangeString: {
                dateStart: moment()
                    .subtract(7, 'day')
                    .startOf('day')
                    .format('DD/MM/YYYY'),
                dateEnd: moment()
                    .subtract(1, 'day')
                    .startOf('day')
                    .format('DD/MM/YYYY'),
            },
            dateRangePreviousString: {
                dateStart: moment()
                    .subtract(8, 'day')
                    .startOf('day')
                    .format('DD/MM/YYYY'),
                dateEnd: moment()
                    .subtract(14, 'day')
                    .startOf('day')
                    .format('DD/MM/YYYY'),
            },
        };
    },

    methods: {
        processData(rawDataFromAPI) {
            let maxShop = 0;
            let maxComeIn = 0;
            let maxRate = 0;
            let maxDwellTime = 0;
            let rawDataStorePerformanceData = [];

            this.totalPages = Math.ceil(rawDataFromAPI.length / this.storePerPage);
            for (let i = 0; i < rawDataFromAPI.length; i++) {
                let shop = rawDataFromAPI[i];
                // check 1000001
                if (shop.gatePercentage == 100000001) {
                    shop.gatePercentage = 0;
                }
                if (shop.rate == 100000001) {
                    shop.rate = 0;
                }
                if (shop.ratePercentage == 100000001) {
                    shop.ratePercentage = 0;
                }
                if (shop.timeAverage == 100000001) {
                    shop.timeAverage = 0;
                }
                if (shop.timeAveragePercentage == 100000001) {
                    shop.timeAveragePercentage = 0;
                }
                if (shop.shopPercentage == 100000001) {
                    shop.shopPercentage = 0;
                }

                // find max of each attribute
                if (shop.gate > maxComeIn) {
                    maxComeIn = shop.gate;
                }
                if (shop.shop > maxShop) {
                    maxShop = shop.shop;
                }
                if (shop.timeAverage > maxDwellTime) {
                    maxDwellTime = shop.timeAverage;
                }
                if (shop.rate > maxRate) {
                    maxRate = shop.rate;
                }

                shop.timeAverageTransform = utils.transformTime(shop.timeAverage);
                rawDataStorePerformanceData.push(shop);
            }
            // tô màu
            for (let i = 0; i < rawDataStorePerformanceData.length; i++) {
                // shop
                rawDataStorePerformanceData[i].colorClassGate = this.chooseColor(
                    rawDataStorePerformanceData[i].gate,
                    maxComeIn
                );
                rawDataStorePerformanceData[i].colorClassShop = this.chooseColor(
                    rawDataStorePerformanceData[i].shop,
                    maxShop
                );
                rawDataStorePerformanceData[i].colorClassTimeAverage = this.chooseColor(
                    rawDataStorePerformanceData[i].timeAverage,
                    maxDwellTime
                );
                rawDataStorePerformanceData[i].colorClassRate = this.chooseColor(
                    rawDataStorePerformanceData[i].rate,
                    maxRate
                );
            }

            this.storePerformanceData = rawDataStorePerformanceData;
            this.changeSortGate(false);
        },
        changeSortShop(sortShopAscending) {
            this.ascending = sortShopAscending;
            this.sortShopAscending = sortShopAscending;
            this.sortGateAscending = false;
            this.sortRateAscending = false;
            this.sortTimeAscending = false;
            this.sortAtribute = 'shop';
        },
        changeSortGate(sortGateAscending) {
            this.ascending = sortGateAscending;
            this.sortGateAscending = sortGateAscending;
            this.sortShopAscending = false;
            this.sortRateAscending = false;
            this.sortTimeAscending = false;
            this.sortAtribute = 'gate';
        },
        changeSortRate(sortRateAscending) {
            this.ascending = sortRateAscending;
            this.sortRateAscending = sortRateAscending;
            this.sortShopAscending = false;
            this.sortGateAscending = false;
            this.sortTimeAscending = false;
            this.sortAtribute = 'rate';
        },
        changeSortTime(sortTimeAscending) {
            this.ascending = sortTimeAscending;
            this.sortTimeAscending = sortTimeAscending;
            this.sortShopAscending = false;
            this.sortGateAscending = false;
            this.sortRateAscending = false;
            this.sortAtribute = 'timeAverage';
        },

        turnPage(position) {
            if (position == 'forward') {
                if (this.page < this.totalPages) {
                    this.page = this.page + 1;
                }
            } else if (position == 'backward') {
                if (this.page > 1) {
                    this.page = this.page - 1;
                }
            } else if (position == 'first') {
                this.page = 1;
            } else if (position == 'last') {
                this.page = this.totalPages;
            }
        },
        chooseColor(value, maxValue) {
            if (value <= (maxValue / 5) * 1) {
                return 'lowestColor';
            } else if (value > (maxValue / 5) * 1 && value <= (maxValue / 5) * 2) {
                return 'lowColor';
            } else if (value > (maxValue / 5) * 2 && value <= (maxValue / 5) * 3) {
                return 'mediumColor';
            } else if (value > (maxValue / 5) * 3 && value <= (maxValue / 5) * 4) {
                return 'highColor';
            } else {
                return 'highestColor';
            }
        },
        dynamicSort(property) {
            let sortOrder = 1;
            if (this.ascending == false) {
                sortOrder = -1;
            }
            return function(a, b) {
                let result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
                if (typeof a[property] === 'string' || a[property] instanceof String) {
                    result = a[property].localeCompare(b[property], undefined, { numeric: true, sensitivity: 'base' });
                }

                return result * sortOrder;
            };
        },
    },
    computed: {
        storePerformanceDataPaginated() {
            return this.storePerformanceData.slice((this.page - 1) * this.storePerPage, this.page * this.storePerPage);
        },
    },
    watch: {
        sortAtribute(val) {
            this.storePerformanceData.sort(this.dynamicSort(val));
        },
        ascending(val) {
            this.storePerformanceData.sort(this.dynamicSort(this.sortAtribute));
        },
    },
};
</script>
<style lang="scss">
.TableKPIRetailStore {
    .Wraptitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .titleAndSub {
            display: flex;
            flex-direction: column;
            .titleTable {
                font-family: 'Roboto';
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: #1f1f1f;
                text-transform: uppercase;
            }
            .subTitle {
                font-family: 'Roboto';
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #5c6066;
                margin-top: 4px;
            }
        }

        .WrapNumber {
            display: flex;
            align-items: center;
            right: 24px;
            position: absolute;
            bottom: 24px;
            .Back {
                height: 24px;
                cursor: pointer;
                .material-icons {
                    font-size: 24;
                    color: #0000008a;
                }
                margin-left: 5px;
            }
            .Number {
                height: 24px;
                width: 24px;
                display: flex;
                font-size: 14px;
                color: #8c8c8c;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
            .Next {
                height: 24px;
                cursor: pointer;
                .material-icons {
                    font-size: 24;
                    color: #0000008a;
                }
                margin-right: 5px;
            }
        }
    }
    .KPIstore {
        border-spacing: 0px;
        width: 100%;
        border: 1px solid #f0f0f0;
        th {
            height: 56px;
            border-right: 1px solid #f0f0f0;
            border-bottom: 1px solid #f0f0f0;
            background-color: #d9d9d9;
            vertical-align: middle;
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #272833;
            width: 20%;
            .material-icons {
                vertical-align: middle;
                cursor: pointer;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.54);
            }
        }
        th:last-child {
            border-right: none;
        }
        td:last-child {
            border-right: none;
        }
        td {
            border-bottom: 1px solid #f0f0f0;
            text-align: center;
            height: 64px;
            border-right: 1px solid #f0f0f0;
            font-family: 'Roboto';
            font-weight: 800;
            vertical-align: middle;
            font-size: 20px;
            line-height: 24px;
            color: #ffffff;
            padding: 8px 12px;
            position: relative;
            width: 20%;
            .Time {
                display: inline-block;
                max-width: 148px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .Balance {
                position: absolute;
                right: 0;
                bottom: 0;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .value {
                    font-family: 'Roboto';
                    font-weight: 800;
                    font-size: 16px;
                    line-height: 24px;
                    text-shadow: 2px 2px 2px rgba(38, 9, 120, 0.5);
                    color: #2bff00;
                }
                .valueDown {
                    font-family: 'Roboto';
                    font-weight: 800;
                    font-size: 16px;
                    line-height: 24px;
                    text-shadow: 2px 2px 2px rgba(38, 9, 120, 0.5);
                    color: #ff5361;
                }

                .material-icons {
                    color: #2bff00;
                }
            }
            img {
                width: 48px;
                height: 48px;
                border-radius: 4px;
            }
        }
        td:first-child {
            background-color: #eaeaea;
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: #272833;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 160px;
        }
        .highestColor {
            background-color: #064b6c;
        }
        .highColor {
            background-color: #43688c;
        }
        .mediumColor {
            background-color: #5c8ebf;
        }
        .lowColor {
            background-color: #68a1d9;
        }
        .lowestColor {
            background-color: #7bbeff;
        }
    }
}
@media only screen and (max-width: 1280px) {
    .TableKPIRetailStore {
        .KPIstore {
            td {
                .Balance {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    .value {
                        font-family: 'Roboto';
                        font-weight: 800;
                        font-size: 12px;
                        line-height: 24px;
                        text-shadow: 2px 2px 2px rgba(38, 9, 120, 0.5);
                        color: #2bff00;
                    }
                    .valueDown {
                        font-family: 'Roboto';
                        font-weight: 800;
                        font-size: 12px;
                        line-height: 24px;
                        text-shadow: 2px 2px 2px rgba(38, 9, 120, 0.5);
                        color: #ff5361;
                    }
                }
            }

            time {
                display: inline-block;
                max-width: 60px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

@media only screen and (max-width: 1100px) {
    .TableKPIRetailStore {
        .KPIstore {
            td {
                .Balance {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    .value {
                        font-family: 'Roboto';
                        font-weight: 800;
                        font-size: 8px;
                        line-height: 24px;
                        text-shadow: 2px 2px 2px rgba(38, 9, 120, 0.5);
                        color: #2bff00;
                    }
                    .valueDown {
                        font-family: 'Roboto';
                        font-weight: 800;
                        font-size: 8px;
                        line-height: 24px;
                        text-shadow: 2px 2px 2px rgba(38, 9, 120, 0.5);
                        color: #ff5361;
                    }
                }
            }

            time {
                display: inline-block;
                max-width: 60px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
</style>
