<template>
    <div>
        <div
            class="search-container-compare"
            v-click-outside="handleClickOutsideChooseTime"
        >
            <span
                class="ValueOption"
                v-on:click="showTimeOptionBindingStyle"
            >
                {{ dateRangeString.dateStart }} -
                {{ dateRangeString.dateEnd }}</span
            >
            <span class="material-icons"> calendar_month </span>
            <div class="ChooseTime" ref="ChooseTime">
                <div class="OptionTime">
                    <span
                        class="option"
                        @click="getTimeRange('fromWeekToToday')"
                        @mouseover="getTimeRangeHover('fromWeekToToday')"
                        @mouseout="handleClickOutsideSpanTimeOption('FromWeekToTodaySpanTimeOption')"
                    >
                        {{ $t("Report.WeekToDate") }}
                        <span
                            class="optionvalue ValueYesterday"
                            ref="FromWeekToTodaySpanTimeOption"
                        >
                            {{ dateRangeStringHover.dateStart }} -
                            {{ dateRangeStringHover.dateEnd }}
                        </span>
                    </span>
                    <span
                        class="option"
                        @click="getTimeRange('fromMonthToToday')"
                        @mouseover="getTimeRangeHover('fromMonthToToday')"
                        @mouseout="handleClickOutsideSpanTimeOption('FromMonthToTodaySpanTimeOption')"
                    >
                        {{ $t("Report.MonthToDate") }}
                        <span
                            class="optionvalue ValueYesterday"
                            ref="FromMonthToTodaySpanTimeOption"
                        >
                            {{ dateRangeStringHover.dateStart }} -
                            {{ dateRangeStringHover.dateEnd }}
                        </span>
                    </span>
                    <span
                        class="option"
                        @click="getTimeRange('fromQuarterToToday')"
                        @mouseover="getTimeRangeHover('fromQuarterToToday')"
                        @mouseout="handleClickOutsideSpanTimeOption('FromQuarterToTodaySpanTimeOption')"
                    >
                        {{ $t("Report.QuarterToDate") }}
                        <span
                            class="optionvalue ValueYesterday"
                            ref="FromQuarterToTodaySpanTimeOption"
                        >
                            {{ dateRangeStringHover.dateStart }} -
                            {{ dateRangeStringHover.dateEnd }}
                        </span>
                    </span>
                    <span
                        class="option"
                        @click="getTimeRange('fromYearToToday')"
                        @mouseover="getTimeRangeHover('fromYearToToday')"
                        @mouseout="handleClickOutsideSpanTimeOption('FromYearToTodaySpanTimeOption')"
                    >
                        {{ $t("Report.YearToDate") }}
                        <span
                            class="optionvalue ValueYesterday"
                            ref="FromYearToTodaySpanTimeOption"
                        >
                            {{ dateRangeStringHover.dateStart }} -
                            {{ dateRangeStringHover.dateEnd }}
                        </span>
                    </span>
                    <span
                        class="option day line"
                        @mouseover="showDatePicker('1')"
                        @mouseout="cancelTimeout('1')"
                    >
                        {{ $t("Report.ByDay") }}
                        <date-picker
                            class="selecttime date"
                            v-model="dateValue"
                            format="YYYY-MM-DD"
                            type="date"
                            :open="showDayPicker"
                            v-click-outside="handleClickOutsideDayPicker"
                            :lang="lang"
                        ></date-picker>
                    </span>
                    <span
                        class="option week"
                        @mouseover="showDatePicker('2')"
                        @mouseout="cancelTimeout('2')"
                    >
                        {{ $t("Report.ByWeek") }}
                        <date-picker
                            class="selecttime weekpicker"
                            v-model="weekValue"
                            type="week"
                            :open="showWeekPicker"
                            v-click-outside="handleClickOutsideWeekPicker"
                            :lang="lang"
                        ></date-picker>
                    </span>
                    <span
                        class="option month"
                        @mouseover="showDatePicker('3')"
                        @mouseout="cancelTimeout('3')"
                    >
                        {{ $t("Report.ByMonth") }}
                        <date-picker
                            class="selecttime monthpicker"
                            v-model="monthValue"
                            type="month"
                            :open="showMonthPicker"
                            v-click-outside="handleClickOutsideMonthPicker"
                            :lang="lang"
                        ></date-picker>
                    </span>
                    <span
                        class="option year"
                        @mouseover="showDatePicker('4')"
                        @mouseout="cancelTimeout('4')"
                    >
                        {{ $t("Report.ByYear") }}
                        <date-picker
                            class="selecttime yearpicker"
                            v-model="yearValue"
                            type="year"
                            :open="showYearPicker"
                            v-click-outside="handleClickOutsideYearPicker"
                            :lang="lang"
                        ></date-picker>
                    </span>
                </div>
                <div class="ValueTime"></div>
            </div>
        </div>
    </div>
</template>

<script>
import * as moment from "moment-timezone";

moment.tz.setDefault("Asia/Ho_Chi_Minh");
moment.locale("vi");

export default {
    mounted() {
        this.$refs["ChooseTime"].style.display = "block";
        this.$refs["ChooseTime"].style.visibility = "visible";
    },
    data() {
        return {
            lang: localStorage.lang=="vn" ? "vi" : "en",
            dayTimeout: "",
            weekTimeout: "",
            monthTimeout: "",
            yearTimeout: "",
            rangeTimeout: "",

            rangeTime: "",
            dateValue: "",
            weekValue: "",
            monthValue: "",
            yearValue: "",

            dateRange: {
                dateStart: moment()
                    .startOf("month")
                    .startOf("day")
                    .format("X"),
                dateEnd: moment()
                    .endOf("month")
                    .startOf("day")
                    .format("X")
            },
            dateRangeString: {
                dateStart: moment()
                    .startOf("month")
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                dateEnd: moment()
                    .startOf("day")
                    .format("DD/MM/YYYY")
            },
            dateRangeStringHover: {
                dateStart: "",
                dateEnd: ""
            },

            showTimeOption: false,
            showDayPicker: false,
            showWeekPicker: false,
            showMonthPicker: false,
            showYearPicker: false,
            showRangePicker: false,
            listDateRange: [],
        }
    },
    methods: {
        cancelTimeout(index) {
            var self = this;
            if (index == "1") {
                clearTimeout(this.dayTimeout);
            } else if (index == "2") {
                clearTimeout(this.weekTimeout);
            } else if (index == "3") {
                clearTimeout(this.monthTimeout);
            } else if (index == "4") {
                clearTimeout(this.yearTimeout);
            }
        },
        showDatePicker(index) {
            var self = this;
            if (index == "1") {
                this.dayTimeout = setTimeout(function() {
                    self.showDayPicker = true;
                    self.showWeekPicker = false;
                    self.showMonthPicker = false;
                    self.showYearPicker = false;
                }, 350);
            } else if (index == "2") {
                this.weekTimeout = setTimeout(function() {
                    self.showDayPicker = false;
                    self.showWeekPicker = true;
                    self.showMonthPicker = false;
                    self.showYearPicker = false;
                }, 350);
            } else if (index == "3") {
                this.monthTimeout = setTimeout(function() {
                    self.showDayPicker = false;
                    self.showWeekPicker = false;
                    self.showMonthPicker = true;
                    self.showYearPicker = false;
                }, 350);
            } else if (index == "4") {
                this.yearTimeout = setTimeout(function() {
                    self.showDayPicker = false;
                    self.showWeekPicker = false;
                    self.showMonthPicker = false;
                    self.showYearPicker = true;
                }, 350);
            }
        },
        showTimeOptionBindingStyle() {
            this.$refs["ChooseTime"].style.display = "block";
            this.$refs["ChooseTime"].style.visibility = "visible";
        },
        handleClickOutsideChooseTime(index) {
            if (index == undefined) {
                this.$refs["ChooseTime"].style.display = "none";
                this.$refs["ChooseTime"].style.visibility = "hidden";
            } else if (
                index.srcElement._prevClass != "mx-icon-right" &&
                index.srcElement._prevClass != "mx-icon-left" &&
                index.srcElement._prevClass != "mx-icon-double-left" &&
                index.srcElement._prevClass != "mx-icon-double-right" &&
                index.srcElement._prevClass != "mx-calendar-header" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-right" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-right"
            ) {
                this.$refs["ChooseTime"].style.display = "none";
                this.$refs["ChooseTime"].style.visibility = "hidden";
            }
        },
        getTimeRange(timeOption) {
            this.compareType = "toNow";
            if (timeOption === "fromWeekToToday") {
                this.selectType = "week";
                this.dateRange.dateStart = moment()
                    .startOf("week")
                    .startOf("day")
                    .format("X");
                this.dateRange.dateEnd = moment()
                    .endOf("week")
                    .startOf("day")
                    .format("X");

                this.dateRangeString.dateStart = moment()
                    .startOf("week")
                    .startOf("day")
                    .format("DD/MM/YYYY");
                this.dateRangeString.dateEnd = moment()
                    .startOf("day")
                    .format("DD/MM/YYYY");
                this.handleClickOutsideChooseTime();
            } else if (timeOption === "fromMonthToToday") {
                this.selectType = "month";
                this.dateRange.dateStart = moment()
                    .startOf("month")
                    .startOf("day")
                    .format("X");
                this.dateRange.dateEnd = moment()
                    .endOf("month")
                    .startOf("day")
                    .format("X");

                this.dateRangeString.dateStart = moment()
                    .startOf("month")
                    .startOf("day")
                    .format("DD/MM/YYYY");
                this.dateRangeString.dateEnd = moment()
                    .startOf("day")
                    .format("DD/MM/YYYY");

                this.handleClickOutsideChooseTime();
                // this.getDataCompareOverall(
                //     this.dateRange.dateStart,
                //     this.dateRange.dateEnd,
                //     this.compareType,
                //     this.group,
                //     this.previousRepeat,
                //     "month"
                // );
            } else if (timeOption === "fromQuarterToToday") {
                this.selectType = "quarter";
                this.dateRange.dateStart = moment()
                    .startOf("quarter")
                    .startOf("day")
                    .format("X");
                this.dateRange.dateEnd = moment()
                    .endOf("quarter")
                    .startOf("day")
                    .format("X");

                this.dateRangeString.dateStart = moment()
                    .startOf("quarter")
                    .startOf("day")
                    .format("DD/MM/YYYY");
                this.dateRangeString.dateEnd = moment()
                    .startOf("day")
                    .format("DD/MM/YYYY");

                this.handleClickOutsideChooseTime();
            } else if (timeOption === "fromYearToToday") {
                this.selectType = "year";
                this.dateRange.dateStart = moment()
                    .startOf("year")
                    .startOf("day")
                    .format("X");
                this.dateRange.dateEnd = moment()
                    .endOf("year")
                    .startOf("day")
                    .format("X");

                this.dateRangeString.dateStart = moment()
                    .startOf("year")
                    .startOf("day")
                    .format("DD/MM/YYYY");
                this.dateRangeString.dateEnd = moment()
                    .startOf("day")
                    .format("DD/MM/YYYY");

                this.handleClickOutsideChooseTime();
            }
            this.$emit("changeDateRange", this.dateRange)
            this.$emit("changeDateRangeString", this.dateRangeString)
            this.$emit("changeSelectType", this.selectType)
            this.$emit("changeCompareType", this.compareType)
        },
        getTimeRangeHover(timeOption) {
            this.showRangePicker = false;
            this.showDayPicker = false;
            this.showWeekPicker = false;
            this.showMonthPicker = false;
            this.showYearPicker = false;
            if (timeOption === "fromWeekToToday") {
                this.$refs["FromWeekToTodaySpanTimeOption"].style.visibility =
                    "visible";
                this.dateRangeStringHover.dateStart = moment()
                    .startOf("week")
                    .startOf("day")
                    .format("DD/MM/YYYY");
                this.dateRangeStringHover.dateEnd = moment()
                    .startOf("day")
                    .format("DD/MM/YYYY");
            } else if (timeOption === "fromMonthToToday") {
                this.$refs["FromMonthToTodaySpanTimeOption"].style.visibility =
                    "visible";
                this.dateRangeStringHover.dateStart = moment()
                    .startOf("month")
                    .startOf("day")
                    .format("DD/MM/YYYY");
                this.dateRangeStringHover.dateEnd = moment()
                    .startOf("day")
                    .format("DD/MM/YYYY");
            } else if (timeOption === "fromQuarterToToday") {
                this.$refs[
                    "FromQuarterToTodaySpanTimeOption"
                ].style.visibility = "visible";
                this.dateRangeStringHover.dateStart = moment()
                    .startOf("quarter")
                    .startOf("day")
                    .format("DD/MM/YYYY");
                this.dateRangeStringHover.dateEnd = moment()
                    .startOf("day")
                    .format("DD/MM/YYYY");
            } else if (timeOption === "fromYearToToday") {
                this.$refs["FromYearToTodaySpanTimeOption"].style.visibility =
                    "visible";
                this.dateRangeStringHover.dateStart = moment()
                    .startOf("year")
                    .startOf("day")
                    .format("DD/MM/YYYY");
                this.dateRangeStringHover.dateEnd = moment()
                    .startOf("day")
                    .format("DD/MM/YYYY");
            }
        },
        handleClickOutsideSpanTimeOption(refInput) {
            this.$refs[refInput].style.visibility = "hidden";
        },
        handleClickOutsideDayPicker(index) {
            if (
                this.showDayPicker == true &&
                index.srcElement._prevClass != "option day line" &&
                index.srcElement._prevClass != "mx-icon-right" &&
                index.srcElement._prevClass != "mx-icon-left" &&
                index.srcElement._prevClass != "mx-icon-double-left" &&
                index.srcElement._prevClass != "mx-icon-double-right" &&
                index.srcElement._prevClass != "mx-calendar-header" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-right" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-right"
            ) {
                this.showDayPicker = false;
            }
        },
        handleClickOutsideWeekPicker(index) {
            if (
                this.showWeekPicker == true &&
                index.srcElement._prevClass != "option week" &&
                index.srcElement._prevClass != "mx-icon-right" &&
                index.srcElement._prevClass != "mx-icon-left" &&
                index.srcElement._prevClass != "mx-icon-double-left" &&
                index.srcElement._prevClass != "mx-icon-double-right" &&
                index.srcElement._prevClass != "mx-calendar-header" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-right" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-right"
            ) {
                this.showWeekPicker = false;
            }
        },
        handleClickOutsideMonthPicker(index) {
            if (
                this.showMonthPicker == true &&
                index.srcElement._prevClass != "option month" &&
                index.srcElement._prevClass != "mx-icon-right" &&
                index.srcElement._prevClass != "mx-icon-left" &&
                index.srcElement._prevClass != "mx-icon-double-left" &&
                index.srcElement._prevClass != "mx-icon-double-right" &&
                index.srcElement._prevClass != "mx-calendar-header" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-right" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-right"
            ) {
                this.showMonthPicker = false;
            }
        },
        handleClickOutsideYearPicker(index) {
            if (
                this.showYearPicker == true &&
                index.srcElement._prevClass != "option year" &&
                index.srcElement._prevClass != "mx-icon-right" &&
                index.srcElement._prevClass != "mx-icon-left" &&
                index.srcElement._prevClass != "mx-icon-double-left" &&
                index.srcElement._prevClass != "mx-icon-double-right" &&
                index.srcElement._prevClass != "mx-calendar-header" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-right" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-right"
            ) {
                this.showYearPicker = false;
            }
        },
    },
    watch: {
        dateValue(val) {
            this.compareType = "periodic";
            this.selectType = "day";
            this.dateRange = {
                dateStart: moment(val)
                    .startOf("day")
                    .format("X"),
                dateEnd: moment(val)
                    .startOf("day")
                    .format("X")
            };
            this.dateRangeString = {
                dateStart: moment(val)
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                dateEnd: moment(val)
                    .startOf("day")
                    .format("DD/MM/YYYY")
            };
            this.showDayPicker = false;
            this.$emit("changeDateRange", this.dateRange)
            this.$emit("changeDateRangeString", this.dateRangeString)
            this.$emit("changeSelectType", this.selectType)
            this.$emit("changeCompareType", this.compareType)
        },
        weekValue(val) {
            this.compareType = "periodic";
            this.selectType = "week";
            this.dateRange = {
                dateStart: moment(val)
                    .startOf("week")
                    .startOf("day")
                    .format("X"),
                dateEnd: moment(val)
                    .endOf("week")
                    .startOf("day")
                    .format("X")
            };
            this.dateRangeString = {
                dateStart: moment(val)
                    .startOf("week")
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                dateEnd: moment(val)
                    .endOf("week")
                    .startOf("day")
                    .format("DD/MM/YYYY")
            };
            this.showWeekPicker = false;
            this.$emit("changeDateRange", this.dateRange)
            this.$emit("changeDateRangeString", this.dateRangeString)
            this.$emit("changeSelectType", this.selectType)
            this.$emit("changeCompareType", this.compareType)
        },
        monthValue(val) {
            this.compareType = "periodic";
            this.selectType = "month";
            this.dateRange = {
                dateStart: moment(val)
                    .startOf("month")
                    .startOf("day")
                    .format("X"),
                dateEnd: moment(val)
                    .endOf("month")
                    .startOf("day")
                    .format("X")
            };
            this.dateRangeString = {
                dateStart: moment(val)
                    .startOf("month")
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                dateEnd: moment(val)
                    .endOf("month")
                    .startOf("day")
                    .format("DD/MM/YYYY")
            };
            this.showMonthPicker = false;
            this.$emit("changeDateRange", this.dateRange)
            this.$emit("changeDateRangeString", this.dateRangeString)
            this.$emit("changeSelectType", this.selectType)
            this.$emit("changeCompareType", this.compareType)
        },
        yearValue(val) {
            this.compareType = "periodic";
            this.selectType = "year";
            this.dateRange = {
                dateStart: moment(val)
                    .startOf("year")
                    .startOf("day")
                    .format("X"),
                dateEnd: moment(val)
                    .endOf("year")
                    .startOf("day")
                    .format("X")
            };
            this.dateRangeString = {
                dateStart: moment(val)
                    .startOf("year")
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                dateEnd: moment(val)
                    .endOf("year")
                    .startOf("day")
                    .format("DD/MM/YYYY")
            };
            this.showYearPicker = false;
            this.$emit("changeDateRange", this.dateRange)
            this.$emit("changeDateRangeString", this.dateRangeString)
            this.$emit("changeSelectType", this.selectType)
            this.$emit("changeCompareType", this.compareType)
        },
    }
}
</script>

<style lang="scss" scoped>
.search-container-compare {
    border: 1px solid #1f1f1f;
    box-sizing: border-box;
    border-radius: 8px;
    height: 44px;
    border-radius: 8px;
    width: 264px;
    padding: 10px 16px;
    background-color: white;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    gap: 8px;
    cursor: pointer;

    .ChooseTime {
        width: 464px;
        position: absolute;
        background-color: white;
        top: 43px;
        left: 0;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        z-index: 100;
        display: flex;
        .OptionTime {
            display: flex;
            flex-direction: column;
            padding: 16px;
            width: 40%;
            border-right: solid 1px #bfbfbf;
            .option {
                padding: 10px;
                font-size: 14px;
                line-height: 20px;
                font-family: "Roboto";
                position: relative;

                .optionvalue {
                    padding: 10px 10px 10px 56px;
                    font-size: 14px;
                    line-height: 20px;
                    font-family: "Roboto";
                    position: absolute;
                    width: 400px;
                    top: 0;
                    left: 140px;
                    color: red;
                    visibility: hidden;
                }
                .selecttime {
                    position: absolute;
                    top: 0px;
                    padding: 0px 10px 10px 56px;
                    left: 105px;
                    visibility: hidden;
                }
                &:hover .date {
                    position: absolute;
                    top: -200px !important;
                    padding: 0px 10px 10px 56px;
                    left: 215px;
                    visibility: hidden;
                }
                &:hover .weekpicker {
                    position: absolute;
                    top: -235px !important;
                    padding: 0px 10px 10px 56px;
                    left: 215px;
                    visibility: hidden;
                }
                &:hover .monthpicker {
                    position: absolute;
                    top: -275px !important;
                    padding: 0px 10px 10px 56px;
                    left: 215px;
                    visibility: hidden;
                }
                &:hover .yearpicker {
                    position: absolute;
                    top: -315px !important;
                    padding: 0px 10px 10px 56px;
                    left: 215px;
                    visibility: hidden;
                }
            }
            .option:hover {
                color: red;
            }
            .line {
                border-top: solid 1px #bfbfbf;
            }
        }
        .ValueTime {
            display: flex;
            flex-direction: column;
            width: 25%;
            width: 75%;
            height: 100%;
            padding: 16px;
            display: none;
            .option {
                padding: 10px;
                font-size: 14px;
                line-height: 20px;
                font-family: "Roboto";
            }
        }
        .rangeTime {
            &:hover .rangepicker {
                visibility: visible;
            }
        }
        .mx-calendar {
            z-index: 999;
            width: 400px;
            top: -144px;
            position: absolute;
            left: 50px;
        }
    }
    .base-input {
        color: #1f1f1f;
        font-weight: 500;
        font-size: 14px;
        font-family: "Roboto";
        line-height: 20px;
    }
}
</style>