<template>
    <div class="multi-choice-container">
        <div @click="HandleClickOption" class="Btn-ChooseGate">
            {{ title }}
        </div>
        <div
            v-show="ShowOption"
            class="WrapOption"
        >
            <span class="Option" @click="handleClickAllSelected">
                <span class="material-icons mr-8" v-show="!isAllSelected">check_box_outline_blank</span>
                <span class="material-icons mr-8" v-show="isAllSelected">check_box</span>
                {{ $t("reportShopingMall.chooseAll") }}
            </span>
            <div class="wrapOtherOption">
                    <span
                        class="Option"
                        v-for="zone in listSelectZone"
                        :key="zone['id']"
                        @click="handleChoose(zone['id'])"
                    >
                        <span class="material-icons mr-8" v-show="!zone['isSelected']">check_box_outline_blank</span> 
                        <span class="material-icons mr-8" v-show="zone['isSelected']">check_box</span>
                        {{ zone["name"] }}
                    </span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SelectMultiChoice',
    props: {
        HandleClickOption: {},
        ShowOption: {},
        listZone: {},
        title: String
    },
    data() {
        return {
            listSelectZone: [],
            isAllSelected: false
        }
    },
    computed: {
        getListSelectZone() {
            return this.listSelectZone.filter(zone => {
                return zone["name"] !== " "
            })
        }
    },
    methods: {
        handleClickAllSelected() {
            this.isAllSelected = !this.isAllSelected
            this.updateListSelectedZoneByIsAllSelected()
        },
        handleChoose(zoneID) {
            let isAllSelected = true
            let listSelectedZoneID = []

            this.listSelectZone = this.listSelectZone.map(zone => {
                if (!zone["isSelected"]) {
                    isAllSelected = false
                }
                
                if (zone["id"] === zoneID) {
                    zone["isSelected"] = !zone["isSelected"]
                }
                if (zone["isSelected"]) {
                    listSelectedZoneID.push(zone["id"])
                }

                return zone
            })
            
            

            if (isAllSelected === false) {
                this.isAllSelected = isAllSelected
            }
            this.$emit("select-multi-choice", listSelectedZoneID)
        },
        updateListSelectedZoneByIsAllSelected() {
            let listSelectedZoneID = []
            this.listSelectZone = this.listSelectZone.map(zone => {
                zone["isSelected"] = false
                if (this.isAllSelected) {
                    listSelectedZoneID.push(zone["id"])
                }

                return zone
            })

            this.$emit("select-multi-choice", listSelectedZoneID)
        }
    },
    watch: {
        listZone: function (listZone) {
            let listSelectZone = []
            let listSelectedZoneID = []

            for (const zone of listZone) {
                listSelectZone.push({
                    "id": zone["id"],
                    "name": zone["name"],
                    "isSelected": false
                })
                // listSelectedZoneID.push(zone["id"])
            }

            this.listSelectZone = listSelectZone
            this.isAllSelected = false
            this.$emit("select-multi-choice", listSelectedZoneID)
        }
    }
}
</script>

<style scoped lang="scss">
.multi-choice-container {
    position: relative;

    .Btn-ChooseGate {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 16px;
        width: auto;
        height: 40px;
        border: 1px solid #276EFF;
        box-sizing: border-box;
        border-radius: 8px;
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #276EFF;
        cursor: pointer;
    }

    .WrapOption {
        position: absolute;
        background: #FFFFFF;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        min-width: 100%;
        top: 40px;
        right: 0;
        z-index: 9;
        padding: 16px;
        max-height: 391px;
        overflow-y: auto;

        .Option {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            padding: 16px;
            color: #333333;
            cursor: pointer;
            max-width: 200px;
            display: flex;
            align-items: center;

            &:hover {
                background-color: #EDF3FF;
            }
        }

        .wrapOtherOption {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            row-gap: 0;
            column-gap: 16px;
        }

        @media screen and (max-width: 1366px) {
            .wrapOtherOption {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                row-gap: 0;
                column-gap: 16px;
                max-height: 300px;
                overflow-y: auto;
            }
        }

        @media screen and (max-width: 1190px) {
            .wrapOtherOption {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                row-gap: 0;
                column-gap: 16px;
                max-height: 300px;
                overflow-y: auto;
            }
        }
    }
}
</style>