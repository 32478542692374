<template>
    <div>
        <div
            class="search-container"
            v-click-outside="handleClickOutsideChooseTime"
        >
            <span
                class="ValueOption"
                v-on:click="showTimeOptionBindingStyle"
                >{{ dateRangeString.dateStart }} -
                {{ dateRangeString.dateEnd }}</span
            >
            <span
                v-on:click="showTimeOptionBindingStyle"
                class="material-icons"
            >
                calendar_month
            </span>
            <div class="ChooseTime" ref="ChooseTime">
                <div class="OptionTime">
                    <span
                        class="option"
                        style="cursor: default;"
                    >
                        {{ $t("Report.ReportTimeToNow")}}
                        <span
                            class="optionvalue NowTime"
                        >
                            {{ $t("Report.Until") }} {{ currentTime }} {{ $t("Report.Today").toLowerCase() }}
                        </span>
                    </span>
                    <span
                        class="option"
                        @click="getTimeRange('yesterday')"
                        @mouseover="getTimeRangeHover('yesterday')"
                        @mouseout="
                            handleClickOutsideSpanTimeOption('YesterdaySpanTimeOption')"
                    >
                        {{ $t("Report.Yesterday") }}
                        <span
                            class="optionvalue ValueYesterday"
                            ref="YesterdaySpanTimeOption"
                        >
                            {{ dateRangeStringHover.dateStart }} -
                            {{ dateRangeStringHover.dateEnd }}
                        </span>
                    </span>
                    <span
                        class="option"
                        @click="getTimeRange('sevenDaysAgo')"
                        @mouseover="getTimeRangeHover('sevenDaysAgo')"
                        @mouseout="
                            handleClickOutsideSpanTimeOption(
                                'SevenDaysAgoSpanTimeOption'
                            )"
                    >
                        {{ $t("Report.TheLastSevenDays") }}
                        <span
                            class="optionvalue ValueYesterday"
                            ref="SevenDaysAgoSpanTimeOption"
                        >
                            {{ dateRangeStringHover.dateStart }} -
                            {{ dateRangeStringHover.dateEnd }}
                        </span>
                    </span>
                    <span
                        class="option"
                        @click="getTimeRange('thirtyDaysAgo')"
                        @mouseover="
                            getTimeRangeHover('thirtyDaysAgo')"
                        @mouseout="
                            handleClickOutsideSpanTimeOption(
                                'ThirtyDaysAgoSpanTimeOption'
                            )"
                    >
                        {{ $t("Report.TheLastThirtyDays") }}
                        <span
                            class="optionvalue ValueYesterday"
                            ref="ThirtyDaysAgoSpanTimeOption"
                        >
                            {{ dateRangeStringHover.dateStart }} -
                            {{ dateRangeStringHover.dateEnd }}
                        </span>
                    </span>
                    <span
                        class="option day line"
                        @mouseover="showDatePicker('1')"
                        @mouseout="cancelTimeout('1')"
                    >
                        {{ $t("Report.ByDay") }}
                        <date-picker
                            class="selecttime date"
                            v-model="dateValue"
                            format="YYYY-MM-DD"
                            type="date"
                            :open="showDayPicker"
                            :lang="lang"
                            v-click-outside="handleClickOutsideDayPicker"
                        ></date-picker>
                    </span>
                    <span
                        class="option week"
                        @mouseover="showDatePicker('2')"
                        @mouseout="cancelTimeout('2')"
                    >
                        {{ $t("Report.ByWeek") }}
                        <date-picker
                            :lang="lang"
                            class="selecttime weekpicker"
                            v-model="weekValue"
                            type="week"
                            :open="showWeekPicker"
                            v-click-outside="handleClickOutsideWeekPicker"
                        ></date-picker>
                    </span>
                    <span
                        class="option month"
                        @mouseover="showDatePicker('3')"
                        @mouseout="cancelTimeout('3')"
                    >
                        {{ $t("Report.ByMonth") }}
                        <date-picker
                            class="selecttime monthpicker"
                            v-model="monthValue"
                            type="month"
                            :open="showMonthPicker"
                            v-click-outside="handleClickOutsideMonthPicker"
                            :lang="lang"
                        ></date-picker>
                    </span>
                    <span
                        class="option year"
                        @mouseover="showDatePicker('4')"
                        @mouseout="cancelTimeout('4')"
                    >
                        {{ $t("Report.ByYear") }}
                        <date-picker
                            class="selecttime yearpicker"
                            v-model="yearValue"
                            type="year"
                            :open="showYearPicker"
                            v-click-outside="handleClickOutsideYearPicker"
                            :lang="lang"
                        ></date-picker>
                    </span>
                    <span
                        class="option rangeTime"
                        @mouseover="showDatePicker('5')"
                        @mouseout="cancelTimeout('5')"
                    >
                        {{ $t("Report.CustomizeDateSelection") }}
                        <date-picker
                            class="selecttime rangepicker"
                            v-model="rangeTime"
                            type="date"
                            range
                            :open="showRangePicker"
                            v-click-outside="handleClickOutsideRangePicker"
                            :lang="lang"
                        >
                        </date-picker>
                    </span>
                </div>
                <div class="ValueTime"></div>
            </div>
        </div>
    </div>
</template>

<script>
import * as moment from "moment-timezone";
import EventBus from "@/EventBus";

export default {
    data() {
        return {
            currentTime: moment().format("HH:mm"),
            lang: localStorage.lang=="vn" ? "vi" : "en",
            dayTimeout: "",
            weekTimeout: "",
            monthTimeout: "",
            yearTimeout: "",
            rangeTimeout: "",
            timeOutYesterDay: "",

            showTimeOption: false,
            showDayPicker: false,
            showWeekPicker: false,
            showMonthPicker: false,
            showYearPicker: false,
            showRangePicker: false,

            dateRange: {
                dateStart: moment()
                    .subtract(7, "day")
                    .startOf("day")
                    .format("X"),
                dateEnd: moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("X")
            },
            dateRangeString: {
                dateStart: moment()
                    .subtract(7, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                dateEnd: moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY")
            },
            dateRangeStringHover: {
                dateStart: "",
                dateEnd: ""
            },

            listDateRange: [],
            rangeTime: "",
            dateValue: "",
            weekValue: "",
            monthValue: "",
            yearValue: "",
        }
    },
    methods: {
        showDatePicker(index) {
            var self = this;
            if (index == "1") {
                this.dayTimeout = setTimeout(function() {
                    self.showDayPicker = true;
                    self.showWeekPicker = false;
                    self.showMonthPicker = false;
                    self.showYearPicker = false;
                    self.showRangePicker = false;
                }, 350);
            } else if (index == "2") {
                this.weekTimeout = setTimeout(function() {
                    self.showDayPicker = false;
                    self.showWeekPicker = true;
                    self.showMonthPicker = false;
                    self.showYearPicker = false;
                    self.showRangePicker = false;
                }, 350);
            } else if (index == "3") {
                this.monthTimeout = setTimeout(function() {
                    self.showDayPicker = false;
                    self.showWeekPicker = false;
                    self.showMonthPicker = true;
                    self.showYearPicker = false;
                    self.showRangePicker = false;
                }, 350);
            } else if (index == "4") {
                this.yearTimeout = setTimeout(function() {
                    self.showDayPicker = false;
                    self.showWeekPicker = false;
                    self.showMonthPicker = false;
                    self.showYearPicker = true;
                    self.showRangePicker = false;
                }, 350);
            } else if (index == "5") {
                this.rangeTimeout = setTimeout(function() {
                    self.showDayPicker = false;
                    self.showWeekPicker = false;
                    self.showMonthPicker = false;
                    self.showYearPicker = false;
                    self.showRangePicker = true;
                }, 350);
            }
        },
        cancelTimeout(index) {
            var self = this;
            if (index == "1") {
                clearTimeout(this.dayTimeout);
            } else if (index == "2") {
                clearTimeout(this.weekTimeout);
            } else if (index == "3") {
                clearTimeout(this.monthTimeout);
            } else if (index == "4") {
                clearTimeout(this.yearTimeout);
            } else if (index == "5") {
                clearTimeout(this.rangeTimeout);
            }
        },
        handleClickOutsideChooseTime(index) {
            if (index == undefined) {
                this.$refs["ChooseTime"].style.display = "none";
                this.$refs["ChooseTime"].style.visibility = "hidden";
            } else if (
                index.srcElement._prevClass != "mx-icon-right" &&
                index.srcElement._prevClass != "mx-icon-left" &&
                index.srcElement._prevClass != "mx-icon-double-left" &&
                index.srcElement._prevClass != "mx-icon-double-right" &&
                index.srcElement._prevClass != "mx-calendar-header" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-right" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-right" &&
                index.srcElement._prevClass !=
                    "mx-calendar mx-calendar-panel-date"
            ) {
                if (navigator.userAgent.indexOf("Firefox") == -1) {
                    if (index.path.length == 15) {
                        if (index.path[9]._prevClass != "mx-datepicker-content") {
                            this.$refs["ChooseTime"].style.display = "none";
                            this.$refs["ChooseTime"].style.visibility = "hidden";
                        }
                    } else {
                        this.$refs["ChooseTime"].style.display = "none";
                        this.$refs["ChooseTime"].style.visibility = "hidden";
                    }
                } else {
                    if (index.target.parentElement._prevClass != "cell" && index.target.parentElement._prevClass != "cell active" && index.target.parentElement._prevClass != "cell today active") {
                        this.$refs["ChooseTime"].style.display = "none";
                        this.$refs["ChooseTime"].style.visibility = "hidden";
                    }
                    
                }
            }
        },
        handleClickOutsideSpanTimeOption(refInput) {
            this.$refs[refInput].style.visibility = "hidden";
        },
        showTimeOptionBindingStyle() {
            this.$refs["ChooseTime"].style.display = "block";
            this.$refs["ChooseTime"].style.visibility = "visible";
        },
        getTimeRange(timeOption) {
            this.compareType = "normal";
            if (timeOption === "yesterday") {
                this.compareText = "dashboard.ComparedToYesterday";
                this.dateRange.dateStart = moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("X");
                this.dateRange.dateEnd = moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("X");

                this.dateRangeString.dateStart = moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY");
                this.dateRangeString.dateEnd = moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY");

                this.handleClickOutsideChooseTime();
                
                this.getDateRange(
                    this.dateRange.dateStart,
                    this.dateRange.dateEnd
                );
            } else if (timeOption === "sevenDaysAgo") {
                this.compareText = "Report.CompareToThePriorSevenDays";
                this.dateRange.dateStart = moment()
                    .subtract(7, "day")
                    .startOf("day")
                    .format("X");
                this.dateRange.dateEnd = moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("X");

                this.dateRangeString.dateStart = moment()
                    .subtract(7, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY");
                this.dateRangeString.dateEnd = moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY");

                this.handleClickOutsideChooseTime();
            } else if (timeOption === "thirtyDaysAgo") {
                this.compareText = "Report.CompareToThePriorThirtyDays";
                this.dateRange.dateStart = moment()
                    .subtract(30, "day")
                    .startOf("day")
                    .format("X");
                this.dateRange.dateEnd = moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("X");

                this.dateRangeString.dateStart = moment()
                    .subtract(30, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY");
                this.dateRangeString.dateEnd = moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY");

                this.handleClickOutsideChooseTime();
            }
            this.getDateRange(this.dateRange.dateStart, this.dateRange.dateEnd);
            this.$emit("changeCompareType", this.compareType)
            this.$emit("changeDateRange", this.dateRange)
            this.$emit("changeDateRangeString", this.dateRangeString)
            this.$emit("changeCompareText", this.compareText)
            var dataDate = {
                compareType: this.compareType,
                dateRange: this.dateRange
            }
            EventBus.$emit("changeDateRetailStore", dataDate);
        },
        getTimeRangeHover(timeOption) {
            this.showRangePicker = false;
            this.showDayPicker = false;
            this.showWeekPicker = false;
            this.showMonthPicker = false;
            this.showYearPicker = false;
            if (timeOption === "yesterday") {
                this.$refs["YesterdaySpanTimeOption"].style.visibility =
                    "visible";
                this.dateRangeStringHover.dateStart = moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY");
                this.dateRangeStringHover.dateEnd = moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY");
            } else if (timeOption === "sevenDaysAgo") {
                this.$refs["SevenDaysAgoSpanTimeOption"].style.visibility =
                    "visible";
                this.dateRangeStringHover.dateStart = moment()
                    .subtract(7, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY");
                this.dateRangeStringHover.dateEnd = moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY");
            } else if (timeOption === "thirtyDaysAgo") {
                this.$refs["ThirtyDaysAgoSpanTimeOption"].style.visibility =
                    "visible";
                this.dateRangeStringHover.dateStart = moment()
                    .subtract(30, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY");
                this.dateRangeStringHover.dateEnd = moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY");
            }
        },
        getDateRange(dateStart, dateEnd) {
            this.showDayPicker = false;
            this.showWeekPicker = false;
            this.showMonthPicker = false;
            this.showYearPicker = false;
            this.showRangePicker = false;
            dateStart = parseInt(dateStart);
            dateEnd = parseInt(dateEnd);
            this.listDateRange = [];
            while (dateStart <= dateEnd) {
                this.listDateRange.push(moment.unix(dateStart).format("DD/MM"));
                dateStart = dateStart + 86400;
            }
            this.$emit("changeListDateRange", this.listDateRange)
        },
        handleClickOutsideDayPicker(index) {
            if (
                this.showDayPicker == true &&
                index.srcElement._prevClass != "option day line" &&
                index.srcElement._prevClass != "mx-icon-right" &&
                index.srcElement._prevClass != "mx-icon-left" &&
                index.srcElement._prevClass != "mx-icon-double-left" &&
                index.srcElement._prevClass != "mx-icon-double-right" &&
                index.srcElement._prevClass != "mx-calendar-header" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-right" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-right"
            ) {
                this.showDayPicker = false;
            }
        },
        handleClickOutsideWeekPicker(index) {
            if (
                this.showWeekPicker == true &&
                index.srcElement._prevClass != "option week" &&
                index.srcElement._prevClass != "mx-icon-right" &&
                index.srcElement._prevClass != "mx-icon-left" &&
                index.srcElement._prevClass != "mx-icon-double-left" &&
                index.srcElement._prevClass != "mx-icon-double-right" &&
                index.srcElement._prevClass != "mx-calendar-header" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-right" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-right"
            ) {
                this.showWeekPicker = false;
            }
        },
        handleClickOutsideMonthPicker(index) {
            if (
                this.showMonthPicker == true &&
                index.srcElement._prevClass != "option month" &&
                index.srcElement._prevClass != "mx-icon-right" &&
                index.srcElement._prevClass != "mx-icon-left" &&
                index.srcElement._prevClass != "mx-icon-double-left" &&
                index.srcElement._prevClass != "mx-icon-double-right" &&
                index.srcElement._prevClass != "mx-calendar-header" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-right" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-right"
            ) {
                this.showMonthPicker = false;
            }
        },
        handleClickOutsideYearPicker(index) {
            if (
                this.showYearPicker == true &&
                index.srcElement._prevClass != "option year" &&
                index.srcElement._prevClass != "mx-icon-right" &&
                index.srcElement._prevClass != "mx-icon-left" &&
                index.srcElement._prevClass != "mx-icon-double-left" &&
                index.srcElement._prevClass != "mx-icon-double-right" &&
                index.srcElement._prevClass != "mx-calendar-header" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-right" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-right"
            ) {
                this.showYearPicker = false;
            }
        },
        handleClickOutsideRangePicker(index) {
            if (
                this.showRangePicker == true &&
                index.srcElement._prevClass != "option rangeTime" &&
                index.srcElement._prevClass != "mx-icon-right" &&
                index.srcElement._prevClass != "mx-icon-left" &&
                index.srcElement._prevClass != "mx-icon-double-left" &&
                index.srcElement._prevClass != "mx-icon-double-right" &&
                index.srcElement._prevClass != "mx-calendar-header" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-right" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-right" &&
                index.srcElement._prevClass !=
                    "mx-calendar mx-calendar-panel-date"
            ) {
                if (navigator.userAgent.indexOf("Firefox") == -1) {
                    if (index.path.length == 15) {
                        if (index.path[9]._prevClass != "mx-datepicker-content") {
                            this.showRangePicker = false;
                        }
                    } else {
                        this.showRangePicker = false;
                    }
                } else {
                    if (index.target.parentElement._prevClass != "cell" && index.target.parentElement._prevClass != "cell active" && index.target.parentElement._prevClass != "cell today active") {
                        this.showRangePicker = false;
                    }
                    
                }
            }
        },
    },
    watch: {
        dateValue(val) {
            var compareToText = "So với ngày ";
            if (localStorage.lang == "en") {
                compareToText = "Compare to ";
            }
            this.compareType = "normal";
            this.compareText =
                compareToText +
                moment(val)
                    .subtract(1, "days")
                    .startOf("day")
                    .format("DD/MM/YYYY");
            this.dateRange = {
                dateStart: moment(val)
                    .startOf("day")
                    .format("X"),
                dateEnd: moment(val)
                    .startOf("day")
                    .format("X")
            };
            this.getDateRange(this.dateRange.dateStart, this.dateRange.dateEnd);
            this.dateRangeString = {
                dateStart: moment(val)
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                dateEnd: moment(val)
                    .startOf("day")
                    .format("DD/MM/YYYY")
            };
            this.$emit("changeCompareType", this.compareType)
            this.$emit("changeDateRange", this.dateRange)
            this.$emit("changeDateRangeString", this.dateRange)
            this.$emit("changeCompareText", this.compareText)
            var dataDate = {
                compareType: this.compareType,
                dateRange: this.dateRange
            }
            EventBus.$emit("changeDateRetailStore", dataDate);
            this.$refs["ChooseTime"].style.display = "none";
            this.$refs["ChooseTime"].style.visibility = "hidden";
        },
        weekValue(val) {
            this.compareType = "normal";
            this.compareText = "Report.CompareToLastWeek";
            this.dateRange = {
                dateStart: moment(val)
                    .startOf("week")
                    .startOf("day")
                    .format("X"),
                dateEnd: moment(val)
                    .endOf("week")
                    .startOf("day")
                    .format("X")
            };
            this.getDateRange(this.dateRange.dateStart, this.dateRange.dateEnd);
            this.dateRangeString = {
                dateStart: moment(val)
                    .startOf("week")
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                dateEnd: moment(val)
                    .endOf("week")
                    .startOf("day")
                    .format("DD/MM/YYYY")
            };
            this.$emit("changeCompareType", this.compareType)
            this.$emit("changeDateRange", this.dateRange)
            this.$emit("changeDateRangeString", this.dateRange)
            this.$emit("changeCompareText", this.compareText)
            var dataDate = {
                compareType: this.compareType,
                dateRange: this.dateRange
            }
            EventBus.$emit("changeDateRetailStore", dataDate);
            this.$refs["ChooseTime"].style.display = "none";
            this.$refs["ChooseTime"].style.visibility = "hidden";
        },
        monthValue(val) {
            this.compareType = "month";
            this.compareText = "Report.CompareToLastMonth";
            this.dateRange = {
                dateStart: moment(val)
                    .startOf("month")
                    .startOf("day")
                    .format("X"),
                dateEnd: moment(val)
                    .endOf("month")
                    .startOf("day")
                    .format("X")
            };
            this.getDateRange(this.dateRange.dateStart, this.dateRange.dateEnd);
            this.dateRangeString = {
                dateStart: moment(val)
                    .startOf("month")
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                dateEnd: moment(val)
                    .endOf("month")
                    .startOf("day")
                    .format("DD/MM/YYYY")
            };
            this.$emit("changeCompareType", this.compareType)
            this.$emit("changeDateRange", this.dateRange)
            this.$emit("changeDateRangeString", this.dateRange)
            this.$emit("changeCompareText", this.compareText)
            var dataDate = {
                compareType: this.compareType,
                dateRange: this.dateRange
            }
            EventBus.$emit("changeDateRetailStore", dataDate);
            this.$refs["ChooseTime"].style.display = "none";
            this.$refs["ChooseTime"].style.visibility = "hidden";
        },
        yearValue(val) {
            this.compareType = "year";
            this.compareText = "Report.CompareToLastYear";
            this.dateRange = {
                dateStart: moment(val)
                    .startOf("year")
                    .startOf("day")
                    .format("X"),
                dateEnd: moment(val)
                    .endOf("year")
                    .startOf("day")
                    .format("X")
            };
            this.getDateRange(this.dateRange.dateStart, this.dateRange.dateEnd);
            this.dateRangeString = {
                dateStart: moment(val)
                    .startOf("year")
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                dateEnd: moment(val)
                    .endOf("year")
                    .startOf("day")
                    .format("DD/MM/YYYY")
            };
            this.$emit("changeCompareType", this.compareType)
            this.$emit("changeDateRange", this.dateRange)
            this.$emit("changeDateRangeString", this.dateRange)
            this.$emit("changeCompareText", this.compareText)
            var dataDate = {
                compareType: this.compareType,
                dateRange: this.dateRange
            }
            EventBus.$emit("changeDateRetailStore", dataDate);
            this.$refs["ChooseTime"].style.display = "none";
            this.$refs["ChooseTime"].style.visibility = "hidden";
        },
        rangeTime(val) {
            this.$refs["ChooseTime"].style.display = "none";
            this.$refs["ChooseTime"].style.visibility = "hidden";
            this.compareType = "normal";
            var dateDelta =
                (moment(val[1])
                    .startOf("day")
                    .format("X") -
                    moment(val[0])
                        .startOf("day")
                        .format("X")) /
                    86400 +
                1;
            var compareToText = "So với ";
            var agoText = " ngày trước";
            if (localStorage.lang == "en") {
                compareToText = "Compare to ";
                agoText = " days ago";
            }
            this.compareText = compareToText + dateDelta + agoText;
            this.dateRange = {
                dateStart: moment(val[0])
                    .startOf("day")
                    .format("X"),
                dateEnd: moment(val[1])
                    .startOf("day")
                    .format("X")
            };
            this.getDateRange(this.dateRange.dateStart, this.dateRange.dateEnd);
            this.dateRangeString = {
                dateStart: moment(val[0])
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                dateEnd: moment(val[1])
                    .startOf("day")
                    .format("DD/MM/YYYY")
            };
            this.$emit("changeCompareType", this.compareType)
            this.$emit("changeDateRange", this.dateRange)
            this.$emit("changeDateRangeString", this.dateRange)
            this.$emit("changeCompareText", this.compareText)
            var dataDate = {
                compareType: this.compareType,
                dateRange: this.dateRange
            }
            EventBus.$emit("changeDateRetailStore", dataDate);
            this.$refs["ChooseTime"].style.display = "none";
            this.$refs["ChooseTime"].style.visibility = "hidden";
        },
    }
}
</script>

<style lang="scss" scoped>

.search-container {
    border: 1px solid #8C8C8C;
    box-sizing: border-box;
    border-radius: 8px;
    height: 44px;
    margin-right: 16px;
    border-radius: 8px;
    width: 264px;
    padding: 10px 16px;
    background-color: white;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    gap: 8px;
    cursor: pointer;

    .ValueOption {
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #434343
    }
    .material-icons{
        font-size: 22px;
    }
    .ChooseTime {
        width: 464px;
        position: absolute;
        background-color: white;
        top: 43px;
        left: 0;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        z-index: 100;
        display: flex;
        visibility: hidden;
        .OptionTime {
            display: flex;
            flex-direction: column;
            padding: 16px;
            width: 36%;
            border-right: solid 1px #bfbfbf;
            .option {
                padding: 10px;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                color: #1F1F1F;
                font-family: "Roboto";
                position: relative;
                .optionvalue {
                    padding: 10px 10px 10px 56px;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                    color: #1F1F1F;
                    font-family: "Roboto";
                    position: absolute;
                    width: 400px;
                    top: 0;
                    left: 105px;
                    color: #FF4343;
                    visibility: hidden;
                }
                .selecttime {
                    position: absolute;
                    top: 0px;
                    padding: 0px 10px 10px 56px;
                    left: 105px;
                    visibility: hidden;
                }
                &:hover {
                    .NowTime {
                        visibility: visible;
                    }
                }
                &:hover .date {
                    position: absolute;
                    top: -150px !important;
                    padding: 0px 10px 10px 56px;
                    left: 170px;
                    visibility: hidden;
                }
                &:hover .weekpicker {
                    position: absolute;
                    top: -200px !important;
                    padding: 0px 10px 10px 56px;
                    left: 170px;
                    visibility: hidden;
                }
                &:hover .monthpicker {
                    position: absolute;
                    top: -240px !important;
                    padding: 0px 10px 10px 56px;
                    left: 170px;
                    visibility: hidden;
                }
                &:hover .yearpicker {
                    position: absolute;
                    top: -280px !important;
                    padding: 0px 10px 10px 56px;
                    left: 170px;
                    visibility: hidden;
                }
                &:hover .rangepicker {
                    position: absolute;
                    top: -280px !important;
                    padding: 0px 10px 10px 56px;
                    left: 335px;
                    visibility: hidden;
                }
            }
            .option:hover {
                color: #FF4343;
            }
            .line {
                border-top: solid 1px #bfbfbf;
            }
        }
        .ValueTime {
            display: flex;
            flex-direction: column;
            width: 25%;
            width: 75%;
            height: 100%;
            padding: 16px;
            display: none;
            .option {
                padding: 10px;
                font-size: 14px;
                line-height: 20px;
                font-family: "Roboto";
            }
        }
        .week {
            &:hover .weekpicker {
            }
        }
        .month {
            &:hover .monthpicker {
            }
        }
        .year {
            &:hover .yearpicker {
            }
        }
        .rangeTime {
            &:hover .rangepicker {
                visibility: visible;
            }
        }
        .mx-calendar {
            z-index: 999;
            width: 400px;
            top: -144px;
            position: absolute;
            left: 50px;
        }
    }
    .base-input {
        color: #1f1f1f;
        font-weight: 500;
        font-size: 14px;
        font-family: "Roboto";
        line-height: 20px;
    }
}
</style>